import React from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoutes({ children }) {
  let token = localStorage.getItem("token");
  try {
    if (token ) {
      return children;
    } else {
      return <Navigate to="/signin" />;
    }
  } catch (error) {
    //localStorage.clear();
    return <Navigate to="/signin" />;
  }
}
