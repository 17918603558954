import React, { useState } from "react";
import Searchbar from "../../Helpers/Searchbar/Searchbar";
import { useContext } from "react";
import { filtrationcontext } from "../../../context/Filtercontext";

export default function NotificationHead() {
  const { setNotificationSearchQuery } = useContext(filtrationcontext);
  const [query, setQuery] = useState("");
  const handleSearch = (e) => {
    setQuery(e.target.value);
    setNotificationSearchQuery(e.target.value);
  };
  return (
    <>
      <div className="bg-white p-16">
        <div className="flex-between">
          <Searchbar
            // eslint-disable-next-line react/style-prop-object
            style="search search-dark input-group"
            path="/assets/search.svg"
            placeholder="Search"
            value={query}
            onChange={handleSearch}
          />
        </div>
      </div>
    </>
  );
}
