import React, { useContext } from "react";
import Close from "../../../../Helpers/CloseBtn/Close";
import { popupcontext } from "../../../../../context/Popupscontext";
import {
	useGetAllUsersQuery,
	useGetChildUsersQuery,
} from "../../../../../Redux/service/Users/Users";
import { useSelector } from "react-redux";

export default function ObjectUsersHead() {
	let { handleClose } = useContext(popupcontext);
	const inf = useSelector((state) => state.users.user);
	const { data: AllUsers } = useGetChildUsersQuery(inf?.id, { skip: !inf?.id });
	const Branches = AllUsers?.filter(
		(user) => user.administrator === false && user.userLimit > 0
	);
	return (
		<>
			<div className="flex-between sticky p-16">
				<div className="d-flex">
					<div className="fs-16 fw-600 me-2">Available Users</div>
					<div className="users-availability">
						<div className="users-count">{AllUsers?.length}</div>
					</div>
				</div>
				<div className="close">
					<Close
						img="Close"
						close={() => {
							handleClose("objectUsers");
						}}
					/>
				</div>
			</div>
			<div className="H-line"></div>
		</>
	);
}
