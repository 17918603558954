import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const CustomVehicleApi = createApi({
	reducerPath: "CustomVehicle",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BACKEND_URL,
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("token");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getAllCustomVehicles: builder.query({
			query: (val) => {
				const page = val.page ?? 1;
				const limit = val.limit ?? 5;
				const sortBy = val.sortby || "id";
				const sortOrder = val.sortorder || "desc";
				return `/vehicles?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
			},
		}),
	}),
});

export const { useGetAllCustomVehiclesQuery } = CustomVehicleApi;
