import React, { useEffect, useState } from "react";
import {
	DatePickerInput,
	InputParentGroup,
	MultiSelect,
} from "../../../Helpers/Input/Input";
import { useGetUsersDeviceQuery } from "../../../../Redux/service/Devices";
import { useSelector } from "react-redux";

export default function ReportSheetsInput({
	setStartDate,
	setEndDate,
	selectedUser,
	setSelectedUser,
	setSelectedVehicles,
}) {
	const inf = useSelector((state) => state.users.user);
	const [options, setOptions] = useState([]);
	const { data } = useGetUsersDeviceQuery(selectedUser, {
		skip: !selectedUser,
	});
	useEffect(() => {
		return setSelectedUser(inf?.id);
	}, [setSelectedUser, inf]);
	useEffect(() => {
		if (data) {
			const formattedOptions = data.map((i) => ({
				value: i.id,
				label: i.name,
			}));
			setOptions(formattedOptions);
		}
	}, [data]);

	const handleDateRangeChange = ({ startDate, endDate }) => {
		setStartDate(startDate ? startDate.toISOString() : null);
		setEndDate(endDate ? endDate.toISOString() : null);
	};

	const handleUserChange = (e) => {
		setSelectedUser(e.target.value);
	};

	return (
		<div className="">
			<InputParentGroup
				id="parent"
				name="parent"
				title="User"
				formstyle="user-form w-100"
				onChange={handleUserChange}
				value={selectedUser || ""}
			/>
			<MultiSelect
				title="Vehicles"
				options={options}
				setSelected={setSelectedVehicles}
			/>

			<DatePickerInput
				id="dateRange"
				name="dateRange"
				title="Date Range"
				formstyle="user-form w-100"
				onChange={handleDateRangeChange}
			/>
		</div>
	);
}
