import React, { useState, useEffect, useContext } from "react";
import TableRow from "../../../../Helpers/Table/TableRow";
import {
  useGetGeofencesEnterAlertQuery,
  useGetGeofencesExitAlertQuery,
} from "../../../../../Redux/service/Alerts/Alerts";
import Loader from "../../../../Helpers/Loader/Loader";
import axios from "axios";
import { formatDateString } from "../../../../../JsHelpers/DateFormat";
import TableRowButton from "../../../../Helpers/Table/TableRowButton";
import Empty from "../../../../Helpers/Empty/Empty";

const GeofencesAlertTableBody = ({ limit, currentPage, setTotalPages }) => {
  const [addresses, setAddresses] = useState([]);

  const {
    data: enterData,
    isLoading: isLoadingEnter,
    isError: isErrorEnter,
  } = useGetGeofencesEnterAlertQuery({
    page: currentPage,
    limit: limit,
  });

  const {
    data: exitData,
    isLoading: isLoadingExit,
    isError: isErrorExit,
  } = useGetGeofencesExitAlertQuery({
    page: currentPage,
    limit: limit,
  });

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (enterData?.alerts && exitData?.alerts) {
      const combinedData = [
        ...enterData.alerts.map((alert) => ({
          ...alert,
          geofence_Type: "Geofence Entered",
        })),
        ...exitData.alerts.map((alert) => ({
          ...alert,
          geofence_Type: "Geofence Exit",
        })),
      ];
      setTableData(combinedData);

      const totalItems =
        (enterData.totalItems || 0) + (exitData.totalItems || 0);
      setTotalPages(Math.ceil(totalItems / limit));
    }
  }, [enterData, exitData, limit, setTotalPages]);

  const fetchGeoCodePosition = async (lat, lon, deviceUniqueId) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lon}&zoom=18&format=jsonv2`
      );
      const newAddress = `${response.data.display_name}`;
      setAddresses((prevAddresses) => {
        const existingIndex = prevAddresses.findIndex(
          (item) => item.deviceUniqueId === deviceUniqueId
        );

        if (existingIndex !== -1) {
          const updatedAddresses = [...prevAddresses];
          updatedAddresses[existingIndex] = {
            deviceUniqueId,
            lat,
            lon,
            newAddress,
          };
          return updatedAddresses;
        } else {
          return [...prevAddresses, { deviceUniqueId, lat, lon, newAddress }];
        }
      });
    } catch (error) {
      console.error("Error fetching geocode position:", error);
    }
  };

  if (isErrorEnter || isErrorExit) {
    <Empty title="Please Try Again Later" subtitle="Error Fetching Data" />;
  }

  if (isLoadingEnter || isLoadingExit) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  if (tableData.length === 0) {
    return (
      <Empty
        title="You have no alerts yet"
        subtitle="Create your first user now!"
      />
    );
  }
  const getAddressForDevice = (deviceUniqueId) => {
    const addressObj = addresses.find(
      (item) => item.deviceUniqueId === deviceUniqueId
    );
    if (addressObj) {
      return (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${addressObj.lat},${addressObj.lon}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary hover-undreline"
        >
          {addressObj.newAddress}
        </a>
      );
    }
    return null;
  };

  return (
    <>
      {tableData.map((alert) => (
        <div key={alert.id} className="table-row">
          <TableRow value={alert.deviceName} />
          <TableRow
            value={alert.deviceUniqueId ? alert.deviceUniqueId : "- - - - -"}
          />
          <TableRowButton
            style="custom-style text-primary cursor"
            valuestyle="value-style"
            onClick={() =>
              fetchGeoCodePosition(alert.latitude, alert.longitude, alert.id)
            }
          >
            {getAddressForDevice(alert.id) || "Get Address"}
          </TableRowButton>
          <TableRow value={alert.geofence_Type} />
          <TableRow
            value={
              alert.eventTime ? formatDateString(alert.eventTime) : "- - - - -"
            }
          />
        </div>
      ))}
    </>
  );
};

export default GeofencesAlertTableBody;
