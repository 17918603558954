import React, { useRef, useEffect, useContext } from "react";
import axios from "axios";
import { webSocketManager } from "../../Sockets/WebSocketManager";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { formattedEmail } from "../Helpers/Email/Email";
import { popupcontext } from "../../context/Popupscontext";

export default function HeaderDropdown() {
  let navigate = useNavigate();
  let inf = useSelector((state) => state.users.user);
  let { setOptions } = useContext(popupcontext);

  const dropdownRef = useRef(null);
  const signingOutRef = useRef(false); // Ref to track if sign-out is in progress

  const handleLogout = async () => {
    signingOutRef.current = true; // Set signing out flag to true
    try {
      // Make sign-out API call
      await axios.delete(`${process.env.REACT_APP_API_URL}/session`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      // Disconnect from WebSocket and clear session
      webSocketManager.disconnect();
      localStorage.setItem("token", "");

      // Reload and navigate to the sign-in page
      window.location.reload();
      navigate("/signin");
    } finally {
      // Close the dropdown after sign-out is handled
      setOptions(false);
      signingOutRef.current = false; // Reset signing out flag
    }
  };

  // Close dropdown when clicking outside, but ignore if sign-out is in progress
  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !signingOutRef.current
    ) {
      setOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="header-dropdown" ref={dropdownRef}>
      <div className="header-dropdown-inf">
        <img
          src={`${process.env.PUBLIC_URL}/assets/loocet/HeaderDropdown.svg`}
          alt="logo"
          width={40}
        />
        <div>
          <div className="header-dropdown-inf-name">{inf.name}</div>
          <div className="header-dropdown-inf-email">
            {formattedEmail(inf.email)}
          </div>
        </div>
      </div>
      <div className="H-line"></div>
      <div className="header-dropdown-options">
        <div onClick={handleLogout}>sign out</div>{" "}
        {/* Updated logout handling */}
      </div>
    </div>
  );
}
