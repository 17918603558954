import React, { useContext, useEffect, useState } from "react";
import OffcanvasFooter from "../../Helpers/Offcanvasfooter/OffcanvasFooter";
import NotifyConfiguration from "../NotificationConfiguration/NotifyConfiguration";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { popupcontext } from "../../../context/Popupscontext";
import {
  useAddNotificationMutation,
  useEditNotificationMutation,
} from "../../../Redux/service/Notifications/Notifications";
import Loader from "../../Helpers/Loader/Loader";
export default function NotificationBody({ onSuccess, Editvalues }) {
  let [InsertNotification, { isLoading: AddLoading }] =
    useAddNotificationMutation();
  let [EditNotification, { isLoading: EditLoading }] =
    useEditNotificationMutation();
  const [submitAttempt, setSubmitAttempt] = useState(false);

  let [channel, setChannel] = useState([]);
  let { handleClose, isEditing, currentNotificationId } =
    useContext(popupcontext);

  useEffect(() => {
    addNotification.setFieldValue(
      "notificators",
      channel.map((i) => i.value)
    );
  }, [channel]);

  let initialValues = {
    type: "",
    notificators: [],
  };
  let validSchema = Yup.object({
    type: Yup.string().required("Type Is Required"),
    notificators: Yup.array()
      .min(1, "At least one channel is required")
      .of(Yup.string()),
  });
  const addNotification = useFormik({
    initialValues,
    validationSchema: validSchema,
    onSubmit: async (val) => {
      try {
        const notificators = val.notificators.join(",");
        if (!isEditing) {
          const Addnotify = await InsertNotification({
            type: val.type,
            notificators,
          }).unwrap();
          toast.success("New Notification is added", { autoClose: 800 });
          onSuccess && (await onSuccess(Addnotify));
        } else {
          const Editnotify = await EditNotification({
            id: currentNotificationId,
            val: {
              id: currentNotificationId,
              type: val.type,
              notificators,
            },
          }).unwrap();
          toast.success("Notification Is Edited Sucessfully", {
            autoClose: 800,
          });
          onSuccess && (await onSuccess(Editnotify)); // Call onSuccess only on success
        }
        //tryRefetch();
        handleClose("form");
      } catch (error) {
        toast.error(`${error?.error}`);
      }
    },
  });

  useEffect(() => {
    if (isEditing && Editvalues) {
      addNotification.setValues(Editvalues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Editvalues]);
  const handleSubmit = (e) => {
    setSubmitAttempt(true);
    addNotification.handleSubmit(e);
  };
  if (AddLoading)
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  if (EditLoading)
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  return (
    <>
      <form onSubmit={handleSubmit}>
        <NotifyConfiguration
          addData={addNotification}
          setChannel={setChannel}
        />
        <OffcanvasFooter
          addData={addNotification}
          submitAttempt={submitAttempt}
        />
      </form>
    </>
  );
}
