import React from "react";
import { formatDate } from "../../../../JsHelpers/DateFormat";

export default function ObjectParameters({ item, name, type }) {
	const getFormattedDate = (serverTime) => formatDate(serverTime);
	const { formattedDate, formattedTime } = getFormattedDate(item);
	return (
		<div className="flex-between display-border p-8">
			<div className="parameter-title">{name}</div>
			{!type ? (
				<div>{item}</div>
			) : (
				<div>{formattedDate + " " + formattedTime}</div>
			)}
		</div>
	);
}
