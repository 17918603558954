import React from "react";
import { InputNotificationType, MultiSelect } from "../../Helpers/Input/Input";

export default function NotifyConfiguration({ setChannel, addData }) {
  let options = [
    { value: "web", label: "Web" },
    { value: "mail", label: "Mail" },
  ];

  const selectedValues = Array.isArray(addData.values.notificators)
    ? addData.values.notificators.map((value) =>
        options.find((option) => option.value === value)
      )
    : addData.values.notificators
    ? addData.values.notificators
        .split(",")
        .map((value) => options.find((option) => option.value === value))
    : [];

  return (
    <div className="userinfo mb-24">
      <p className="fs-16 fw-700 brand-700 text-uppercase">
        Notification Config
      </p>
      <InputNotificationType
        title="Type"
        type="text"
        formstyle="user-form w-100"
        id="type"
        name="type"
        onBlur={addData.handleBlur}
        onChange={addData.handleChange}
        errors={addData.errors.type}
        touched={addData.touched.type}
        value={addData.values.type || ""}
      />
      <MultiSelect
        title="Channels"
        options={options}
        setSelected={setChannel}
        onBlur={addData.handleBlur}
        value={selectedValues}
        errors={addData.errors.notificators}
        touched={addData.touched.notificators}
      />
    </div>
  );
}
