import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
	useGetAlarmReportQuery,
	useGetDistanceReportQuery,
	useGetFuelReportQuery,
	useGetOdometerReportQuery,
	useGetSpeedReportQuery,
	useGetStopReportQuery,
	useGetVehicleRoutesQuery,
	useGetVehicleTripsQuery,
} from "../../../Redux/service/Reports/Reports";
import Table from "../../Helpers/Table/Table";
import ReportSheetsHead from "./ReportSheetsHead/ReportSheetsHead";
import ReportSheetsTotal from "./ReportTotals/ReportSheetsTotal";
import { useSelector } from "react-redux";
import Pagination from "../../Helpers/Pagination/Pagination";
import { filtrationcontext } from "../../../context/Filtercontext";
import { toast } from "react-toastify";
import {
	useGetOudometeReportQuery,
	useGetRoutesReportQuery,
	useGetSpeedExportQuery,
	useGetStopsExportQuery,
	useGetTripsReportQuery,
} from "../../../Redux/service/ExportReports/ExportReports";

export default function ReportSheets() {
	const { pathname } = useLocation();
	const inf = useSelector((state) => state.users.user);
	const { limit, setLimit, totalPages } = useContext(filtrationcontext);

	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [selectedUser, setSelectedUser] = useState(inf.id);
	const [selectedVehicles, setSelectedVehicles] = useState([]);
	const [selectedids, setIds] = useState([]);
	const [exportedIds, setExportedIds] = useState([]);
	const [name, setName] = useState("");
	const [isShown, setIsShown] = useState(false);
	const [reportData, setReportData] = useState([]);
	const [exportedData, setExportedData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [trigger, setTrigger] = useState(0);

	let from, to;
	const [currentPage, setCurrentPage] = useState(
		Number(localStorage.getItem("page")) || 1
	);
	useEffect(() => {
		setLimit(10);
	}, [setLimit]);

	useEffect(() => {
		localStorage.setItem("page", currentPage);
	}, [currentPage]);

	useEffect(() => {
		return () => {
			localStorage.setItem("page", 1);
		};
	}, []);
	const isFirstRender = useRef(true);

	// Handle page click in Pagination
	const handlePageClick = (data) => {
		const selectedPage = data.selected + 1;
		setIsShown(true);
		setCurrentPage(selectedPage);
	};
	const Show = () => {
		setIsShown(true);
	};
	const getShortPath = () => pathname.split("/").pop();

	if (startDate && endDate) {
		from = new Date(startDate).toISOString().replace(/:/g, "%3A");
		to = new Date(endDate).toISOString().replace(/:/g, "%3A");
	}
	// Vehicle tracking report
	const {
		data: vehicleTripReport,
		isLoading: tripLoading,
		refetch: vehicleTripRefetch,
	} = useGetVehicleTripsQuery(
		{ from, to, selectedids, page: currentPage, pageSize: limit },
		{
			skip:
				!startDate ||
				!endDate ||
				selectedids.length === 0 ||
				!isShown ||
				!currentPage ||
				!limit ||
				getShortPath() !== "vehiclestrips",
		}
	);
	const { data: exportedVehiclestripsReport } = useGetTripsReportQuery(
		{ from, to, exportedIds },
		{
			skip:
				!startDate ||
				!endDate ||
				exportedIds.length === 0 ||
				getShortPath() !== "vehiclestrips",
		}
	);

	// Speed report
	const {
		data: vehicleSpeedReport,
		isLoading: speedLoading,
		refetch: speedRefetch,
	} = useGetSpeedReportQuery(
		{ from, to, selectedids, page: currentPage, pageSize: limit },
		{
			skip:
				!startDate ||
				!endDate ||
				selectedids.length === 0 ||
				!isShown ||
				!currentPage ||
				!limit ||
				getShortPath() !== "speed",
		}
	);
	const { data: exportedSpeedReport } = useGetSpeedExportQuery(
		{ from, to, exportedIds },
		{
			skip:
				!startDate ||
				!endDate ||
				exportedIds.length === 0 ||
				getShortPath() !== "speed",
		}
	);

	// Alarm report
	const {
		data: vehicleAlarmReport,
		isLoading: alarmLoading,
		refetch: alarmRefetch,
	} = useGetAlarmReportQuery(
		{ from, to, selectedids },
		{
			skip:
				!startDate ||
				!endDate ||
				selectedids.length === 0 ||
				!isShown ||
				getShortPath() !== "alarm",
		}
	);

	// Stop report
	const {
		data: vehicleStopReport,
		isLoading: stopLoading,
		refetch: stopRefetch,
	} = useGetStopReportQuery(
		{ from, to, selectedids, page: currentPage, pageSize: limit },
		{
			skip:
				!startDate ||
				!endDate ||
				selectedids.length === 0 ||
				!isShown ||
				!currentPage ||
				!limit ||
				getShortPath() !== "stop",
		}
	);
	const { data: exportedStopReport } = useGetStopsExportQuery(
		{ from, to, exportedIds },
		{
			skip:
				!startDate ||
				!endDate ||
				exportedIds.length === 0 ||
				getShortPath() !== "stop",
		}
	);

	// Distance report
	const {
		data: vehicleDistanceReport,
		isLoading: distanceLoading,
		refetch: distanceRefetch,
	} = useGetDistanceReportQuery(
		{ from, to, selectedids, page: currentPage, pageSize: limit },
		{
			skip:
				!startDate ||
				!endDate ||
				selectedids.length === 0 ||
				!isShown ||
				!currentPage ||
				!limit ||
				getShortPath() !== "distance",
		}
	);
	const { data: exportedDistanceReport } = useGetSpeedExportQuery(
		{ from, to, exportedIds },
		{
			skip:
				!startDate ||
				!endDate ||
				exportedIds.length === 0 ||
				getShortPath() !== "distance",
		}
	);

	//Route Report
	const {
		data: vehicleRouteReport,
		isLoading: RouteLoading,
		refetch: vehicleRouteRefetch,
	} = useGetVehicleRoutesQuery(
		{ from, to, selectedids, page: currentPage, pageSize: limit },
		{
			skip:
				!startDate ||
				!endDate ||
				selectedids.length === 0 ||
				!isShown ||
				!currentPage ||
				!limit ||
				getShortPath() !== "route",
		}
	);
	const { data: exportedRoute } = useGetRoutesReportQuery(
		{ from, to, exportedIds },
		{
			skip:
				!startDate ||
				!endDate ||
				exportedIds.length === 0 ||
				getShortPath() !== "route",
		}
	);

	//Fuel Report
	const {
		data: vehicleFuelReport,
		isLoading: fuelLoading,
		refetch: vehicleFuelRefetch,
	} = useGetFuelReportQuery(
		{ from, to, selectedids, page: currentPage, pageSize: limit },
		{
			skip:
				!startDate ||
				!endDate ||
				selectedids.length === 0 ||
				!isShown ||
				!currentPage ||
				!limit ||
				getShortPath() !== "fuel",
		}
	);
	const { data: exportedFuelReport } = useGetSpeedExportQuery(
		{ from, to, exportedIds },
		{
			skip:
				!startDate ||
				!endDate ||
				exportedIds.length === 0 ||
				getShortPath() !== "fuel",
		}
	);

	//Odometer Report
	const {
		data: vehicleOdometerReport,
		isLoading: odometerLoading,
		refetch: odometerRefetch,
	} = useGetOdometerReportQuery(
		{ from, to, selectedids, page: currentPage, pageSize: limit },
		{
			skip:
				!startDate ||
				!endDate ||
				selectedids.length === 0 ||
				!isShown ||
				!currentPage ||
				!limit ||
				getShortPath() !== "odometer",
		}
	);
	const { data: exportedOdometerReport } = useGetOudometeReportQuery(
		{ from, to, exportedIds },
		{
			skip:
				!startDate ||
				!endDate ||
				exportedIds.length === 0 ||
				getShortPath() !== "odometer",
		}
	);

	useEffect(() => {
		if (selectedVehicles) {
			const ids = selectedVehicles.map((i) => Number(i.value)).join(",");
			setIds(ids);
			const longIds = selectedVehicles
				.map((id) => `deviceId=${id.value}`)
				.join("&");
			setExportedIds(longIds);
			setName(selectedVehicles?.[0]?.label);
		}
	}, [selectedVehicles]);

	useEffect(() => {
		const fetchData = async () => {
			if (isShown) {
				if (!startDate || !endDate) {
					toast.error("Please select at least one date", { autoClose: 800 });
					setIsShown(false); // Reset isShown if dates are missing
					return; // Exit fetchData early if no dates are provided
				}
				if (isLoading) return;
				setIsLoading(true);
				const currentPath = getShortPath();
				let fetchReport;
				let fetchExport;

				try {
					switch (currentPath) {
						case "vehiclestrips":
							fetchReport = vehicleTripRefetch();
							break;
						case "speed":
							fetchReport = speedRefetch();
							break;
						case "alarm":
							fetchReport = alarmRefetch();
							break;
						case "stop":
							fetchReport = stopRefetch();
							break;
						case "distance":
							fetchReport = distanceRefetch();
							break;
						case "route":
							fetchReport = vehicleRouteRefetch();
							break;
						case "fuel":
							fetchReport = vehicleFuelRefetch();
							break;
						case "odometer":
							fetchReport = odometerRefetch();
							break;
						default:
							fetchReport = Promise.resolve({ data: [] });
					}

					if (fetchReport) {
						const { data } = await fetchReport;
						setReportData(data);
					}
				} catch (error) {
					toast.error("An error occurred while fetching data");
				} finally {
					setIsLoading(false);
					setIsShown(false); // Reset the trigger
					switch (currentPath) {
						case "vehiclestrips":
							fetchExport = exportedVehiclestripsReport;
							break;
						case "speed":
							fetchExport = exportedSpeedReport;
							break;
						case "stop":
							fetchExport = exportedStopReport;
							break;
						case "distance":
							fetchExport = exportedDistanceReport;
							console.log(fetchExport);
							break;
						case "route":
							if (exportedRoute?.length > 99) {
								let combinedArray = [];
								combinedArray = [...combinedArray, ...exportedRoute];
								fetchExport = combinedArray;
							} else {
								fetchReport = exportedRoute;
							}
							console.log(fetchExport);
							break;
						case "fuel":
							fetchExport = exportedFuelReport;
							break;
						case "odometer":
							fetchExport = exportedOdometerReport;
							break;
						default:
							fetchReport = Promise.resolve({ data: [] });
					}
					setExportedData(fetchExport);
				}
			}
		};

		fetchData();
	}, [
		isShown,
		from,
		to,
		selectedids,
		vehicleTripRefetch,
		speedRefetch,
		alarmRefetch,
		stopRefetch,
		distanceRefetch,
		vehicleFuelRefetch,
		vehicleRouteRefetch,
		pathname,
		getShortPath,
		odometerRefetch,
		currentPage,
		trigger,
		limit,
		startDate,
		endDate,
	]);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		} else {
			setIsShown(true);
		}
	}, [limit]);
	useEffect(() => {
		return () => setIsShown(false);
	}, [pathname]);
	const getHeadField = () => {
		let HeadField = [];
		switch (getShortPath()) {
			case "vehiclestrips":
				HeadField = [
					"Vehicle",
					"Start Time",
					"Start Address",
					"Odometer Start",
					"End Time",
					"End Address",
					"Odometer End",
					"AVG Speed",
					"Distance",
					"Duration",
				];
				break;
			case "speed":
				HeadField = [
					"Vehicle",
					"Start Time",
					"End Time",
					"Max Speed",
					"Average Speed",
					"Engine Hours",
				];
				break;
			case "alarm":
				HeadField = ["Fix Time", "Type", "Data"];
				break;
			case "stop":
				HeadField = [
					"Vehicle",
					"Start Time",
					"End Time",
					"Duration",
					"Odometer",
					"Address",
				];
				break;
			case "distance":
				HeadField = [
					"Vehicle",
					"Date",
					"Distance",
					"Start Odometer",
					"End Odometer",
				];
				break;
			case "route":
				HeadField = [
					"Name",
					"Date",
					"Address",
					"Speed",
					"Distance",
					"Total Distance",
					"Ignition",
					"Odometer",
					"Event",
					"Power",
					"Battery Level",
				];
				break;
			case "fuel":
				HeadField = ["Vehicle", "Spent Fuel", "Engine Hours", "Total Distance"];
				break;
			case "odometer":
				HeadField = ["Vehicle", "Odometer", "Date", "Spent Fuel"];
				break;
			default:
				HeadField = [];
		}
		return HeadField;
	};
	return (
		<>
			<div className="report-sheets">
				<ReportSheetsHead
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					selectedUser={selectedUser}
					setSelectedUser={setSelectedUser}
					setSelectedVehicles={setSelectedVehicles}
					isShown={isShown}
					setIsShown={setIsShown}
				/>

				{reportData?.length !== 0 && <ReportSheetsTotal data={reportData} />}
				<Table
					HeadField={getHeadField()}
					BodyData={reportData?.data}
					exportData={exportedData}
					name={name}
					Loading={isLoading}
					type="reportsheet"
				/>
			</div>
			{reportData?.length !== 0 && reportData?.data?.length !== 0 && (
				<Pagination
					handlePageClick={handlePageClick}
					currentPage={currentPage}
					//onClick={Show}
					pageCount={
						totalPages
							? Math.ceil(totalPages / limit)
							: Math.ceil(reportData?.totalPages)
					}
				/>
			)}
		</>
	);
}
