import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { popupcontext } from "../../context/Popupscontext";
import SecondHeader from "../Header/SecondHeader";
import Table from "../Helpers/Table/Table";
import Loader from "../Helpers/Loader/Loader";
import NotificationHead from "./NotificationParts/NotificationHead";
import NotificationForm from "./NotificationForm/NotificationForm";
import { useGetAllNotificationsQuery } from "../../Redux/service/Notifications/Notifications";

export default function Notification() {
	const { handleShow, setEdited } = useContext(popupcontext);
	const HeadField = ["Type", "Channel", "Actions"];
	let { data, isLoading, refetch } = useGetAllNotificationsQuery();
	const handleSuccess = (newnotification) => {
		if (newnotification) {
			setEdited(true);
			refetch();
		}
	};

	return (
		<div>
			<Helmet>
				<title>Notifications</title>
				<meta name="description" content="Notifications" />
			</Helmet>
			<SecondHeader
				title="Notifications"
				add="Add notification"
				onClick={() => handleShow("addForm")}
			/>
			<NotificationHead />
			<div className="report-sheets">
				{isLoading ? (
					<>
						<div className="loader-container loader-table">
							<Loader />
						</div>
					</>
				) : (
					<div className="notify-table">
						<Table HeadField={HeadField} BodyData={data} type="notifications" />
					</div>
				)}
			</div>
			<NotificationForm onSuccess={handleSuccess} />
		</div>
	);
}
