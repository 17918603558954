import React, { useContext, useState } from "react";
import { filtrationcontext } from "../../../context/Filtercontext";
import TableHeadItem from "../../Helpers/Table/TableHeadItem";

export default function VehicleTableHeaders() {
	const { Sort, setSort } = useContext(filtrationcontext);
	const [hoveredColumn, setHoveredColumn] = useState(null);
	const Sorting = (type) => {
		setSort((prevSort) => ({
			type: type,
			value: !prevSort.value,
		}));
	};

	return (
		<>
			<div className="table-head-container">
				<div className={`table-head d-flex p-16`}>
					<TableHeadItem
						title="name"
						Sort={() => Sorting("name")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "name"}
						onMouseEnter={() => setHoveredColumn("name")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>
					<TableHeadItem
						title="plate number"
						Sort={() => Sorting("plate number")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "plate number"}
						onMouseEnter={() => setHoveredColumn("plate number")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>
					<TableHeadItem
						title="status"
						Sort={() => Sorting("status")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "status"}
						onMouseEnter={() => setHoveredColumn("status")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>
					<TableHeadItem
						title="IMEI"
						Sort={() => Sorting("imei")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "imei"}
						onMouseEnter={() => setHoveredColumn("imei")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>
					<TableHeadItem
						title="last update"
						Sort={() => Sorting("last update")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "last update"}
						onMouseEnter={() => setHoveredColumn("last update")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>
					<div className="fs-14 fw-400 text-capitalize table-element">
						condition
					</div>
					<TableHeadItem
						title="parent"
						Sort={() => Sorting("parent")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "parent"}
						onMouseEnter={() => setHoveredColumn("parent")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>
					<div className="fs-14 fw-400 text-capitalize table-element">
						actions
					</div>
				</div>
			</div>
		</>
	);
}
