import React from "react";

export default function Loader() {
  return (
    <>
      <div className="loader">
        <img
          src={`${process.env.PUBLIC_URL}/assets/Loader.svg`}
          alt="loading"
        />
      </div>
    </>
  );
}
