import React, { useContext, useEffect } from "react";
import FormHeader from "../../Helpers/Form/FormParts/FormHeader";
import { Offcanvas } from "react-bootstrap";
import { popupcontext } from "../../../context/Popupscontext";
import NotificationBody from "../NotificationBody/NotificationBody";
import Filter from "../../Helpers/Filter/Filter";
import { useGetSpecificNotificationsQuery } from "../../../Redux/service/Notifications/Notifications";

export default function NotificationForm({ onSuccess }) {
  const { form, isEditing, currentNotificationId } = useContext(popupcontext);
  const { refetch, data: notificationDetails } =
    useGetSpecificNotificationsQuery(currentNotificationId, {
      skip: !currentNotificationId,
    });
  useEffect(() => {
    if (isEditing && currentNotificationId) {
      refetch();
    }
  }, [currentNotificationId, isEditing, notificationDetails, refetch]);
  return (
    <div>
      <Filter show={form}>
        <Offcanvas.Header>
          <FormHeader
            title={!isEditing ? "Add Notification" : "Edit Notification"}
          />
        </Offcanvas.Header>
        <div className="H-line"></div>
        <Offcanvas.Body>
          <NotificationBody
            onSuccess={onSuccess}
            Editvalues={notificationDetails}
          />
        </Offcanvas.Body>
      </Filter>
    </div>
  );
}
