/* eslint-disable react/style-prop-object */
import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../Helpers/Input/Input";
import Button from "../../../../../../Helpers/Button/Button";
import {
  useGetSpecificDeviceQuery,
  useUpdateDeviceByIdMutation,
} from "../../../../../../../Redux/Devicestwo";
import { toast } from "react-toastify";

const FuelIncreaseThreshold = ({
  device,
  setControlsPopup,
  setIsControlsPopup,
}) => {
  const {
    data: oldData,
    refetch,
    isLoading,
  } = useGetSpecificDeviceQuery(device.id);
  const [updateDeviceById] = useUpdateDeviceByIdMutation();

  const formik = useFormik({
    initialValues: {
      FuelIncreaseThreshold:
        oldData?.[0].attributes?.FuelIncreaseThreshold || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      FuelIncreaseThreshold: Yup.number().required(
        "Fuel Increase Threshold is required"
      ),
    }),
    onSubmit: async (val) => {
      try {
        const body = {
          id: device.id,
          attributes: {
            speedLimit: oldData?.[0].attributes?.speedLimit,
            FuelIncreaseThreshold: val.FuelIncreaseThreshold,
            fuelDropThreshold: oldData?.[0].attributes?.fuelDropThreshold,
          },
          groupId: oldData?.[0].groupId,
          calendarId: oldData?.[0].calendarId,
          name: oldData?.[0].name,
          uniqueId: oldData?.[0].uniqueId,
          status: oldData?.[0].status,
          lastUpdate: oldData?.[0].lastUpdate,
          positionId: oldData?.[0].positionId,
          phone: oldData?.[0].phone,
          model: oldData?.[0].model,
          contact: oldData?.[0].contact,
          category: oldData?.[0].category,
          disabled: oldData?.[0].disabled,
          expirationTime: oldData?.[0].expirationTime,
        };
        await updateDeviceById({ id: device.id, val: body }).unwrap();
        await refetch();
        setControlsPopup("");
        setIsControlsPopup(false);
        toast.success("Fuel Increase Threshold has been set successfully", {
          autoClose: 800,
        });
      } catch (error) {
        toast.success(
          "Error while setting fuel increase threshold, please try again later",
          {
            autoClose: 800,
          }
        );
      }
    },
  });

  if (isLoading) return <>Loading...</>;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <Input
          title={"Enter Fuel Increase Threshold"}
          type="number"
          placeholder="100 liter"
          id="FuelIncreaseThreshold"
          name="FuelIncreaseThreshold"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.FuelIncreaseThreshold}
        />
        {formik.touched.FuelIncreaseThreshold &&
        formik.errors.FuelIncreaseThreshold ? (
          <div>{formik.errors.FuelIncreaseThreshold}</div>
        ) : null}
        <div className="w-100 flex-row justify-end">
          <Button
            text="Save"
            type="submit"
            style="button fs-14 p-6-12 btn-success mt-12"
          />
        </div>
      </div>
    </form>
  );
};

export default FuelIncreaseThreshold;
