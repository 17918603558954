import React, { useEffect } from "react";
import {
	DatePickerInput,
	InputDevicesGroup,
	InputParentGroup,
} from "../../Helpers/Input/Input";
import { useSelector } from "react-redux";

export default function EventsInput({
	setStartDate,
	setEndDate,
	selectedUser,
	selectedId,
	setSelectedUser,
	setSelectedVehicles,
}) {
	const inf = useSelector((state) => state.users.user);

	useEffect(() => {
		return setSelectedUser(inf?.id);
	}, [setSelectedUser, inf]);

	const handleDateRangeChange = ({ startDate, endDate }) => {
		setStartDate(startDate ? startDate.toISOString() : null);
		setEndDate(endDate ? endDate.toISOString() : null);
	};

	const handleUserChange = (e) => {
		setSelectedUser(e.target.value);
	};
	const handleVehicleIdChange = (e) => {
		setSelectedVehicles(e.target.value);
	};

	return (
		<div className="">
			<InputParentGroup
				id="parent"
				name="parent"
				title="User"
				formstyle="user-form w-100"
				onChange={handleUserChange}
				value={selectedUser || ""}
			/>
			<InputDevicesGroup
				title="Vehicle"
				formstyle="user-form w-100"
				placeholder="Select Vehicle"
				value={selectedId || ""}
				userID={selectedUser}
				onChange={handleVehicleIdChange}
			/>
			<DatePickerInput
				id="dateRange"
				name="dateRange"
				title="Date Range"
				formstyle="user-form w-100"
				onChange={handleDateRangeChange}
			/>
		</div>
	);
}
