import React from "react";
import ObjectSelectedSensorsRows from "./ObjectSelectedSensorsRows";
import ObjectParameters from "./ObjectParameters";

export default function ObjectSelectedSensors({ position, CarSensors }) {
	return (
		<>
			<div className="object-selected-sensors">
				<ObjectSelectedSensorsRows
					title="speed"
					val={`${Math.round(position?.speed)} km/h`}
					img="baselinespeed"
					position={position}
				/>
				{CarSensors?.fuelSupport && (
					<ObjectSelectedSensorsRows title="fuel" val="N/A" img="Fuel" />
				)}
				{CarSensors?.temperatureSensorSupport && (
					<ObjectSelectedSensorsRows
						title="temperature"
						val="N/A"
						img="Temperature"
					/>
				)}
				{CarSensors?.accSupport && (
					<ObjectSelectedSensorsRows
						title="ignition"
						val="OFF"
						img="Ignition"
					/>
				)}
				{CarSensors?.doorSupport && (
					<ObjectSelectedSensorsRows title="door" val="Closed" img="Door" />
				)}
				{Object.keys(position.attributes).length !== 0 && (
					<>
						<div className="parameters mb-2">
							<div>Parameters</div>
						</div>
						{Object.entries(position.attributes).map(([key, value]) => {
							return (
								<>
									<div key={key} className="flex-between display-border p-8">
										<div className="parameter-title">{key}</div>
										<div>{Math.round(value)}</div>
									</div>
								</>
							);
						})}

						<ObjectParameters name="protocol" item={position.protocol} />
						<ObjectParameters name="network" item={position.network} />
						<ObjectParameters name="valid" item={position.valid} />
						<ObjectParameters name="outdated" item={position.outdated} />
						<ObjectParameters name="latitude" item={position.latitude} />
						<ObjectParameters name="longitude" item={position.longitude} />
						<ObjectParameters name="altitude" item={position.altitude} />
						<ObjectParameters
							name="device time"
							item={position.deviceTime}
							type="date"
						/>
						<ObjectParameters
							name="fix time"
							item={position.fixTime}
							type="date"
						/>
						<ObjectParameters
							name="server time"
							item={position.serverTime}
							type="date"
						/>
					</>
				)}
			</div>
		</>
	);
}
