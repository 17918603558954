import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const ExportsApi = createApi({
  reducerPath: "Exports",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/reports`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Accept", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSpeedExport: builder.query({
      query: ({ from: startdate, to: enddate, exportedIds: ids }) => {
        return `/summary?from=${startdate}&to=${enddate}&daily=true&${ids}`;
      },
    }),
    getStopsExport: builder.query({
      query: ({ from: startdate, to: enddate, exportedIds: ids }) => {
        return `/stops?from=${startdate}&to=${enddate}&daily=true&${ids}`;
      },
    }),
    getOudometeReport: builder.query({
      query: ({ from: startdate, to: enddate, exportedIds: ids }) => {
        return `/summary?from=${startdate}&to=${enddate}&daily=false&${ids}`;
      },
    }),
    getRoutesReport: builder.query({
      query: ({ from: startdate, to: enddate, exportedIds: ids }) => {
        return `/route?from=${startdate}&to=${enddate}&${ids}`;
      },
    }),
    getTripsReport: builder.query({
      query: ({ from: startdate, to: enddate, exportedIds: ids }) => {
        return `/trips?from=${startdate}&to=${enddate}&${ids}`;
      },
    }),
  }),
});

export const {
  useGetSpeedExportQuery,
  useGetStopsExportQuery,
  useGetOudometeReportQuery,
  useGetRoutesReportQuery,
  useGetTripsReportQuery,
} = ExportsApi;
