/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import Button from "../../../../../../Helpers/Button/Button";
import Close from "../../../../../../Helpers/CloseBtn/Close";
import {
  useAddPermissionMutation,
  useDeletePermissionMutation,
} from "../../../../../../../Redux/service/Permissions/Permissions";
import { toast } from "react-toastify";
import {
  useGetAllNotificationsQuery,
  useGetNotificationsByDeviceIdQuery,
} from "../../../../../../../Redux/service/Notifications/Notifications";

const SetNotification = ({ title, device }) => {
  const [selectedNotification, setSelectedNotification] = useState([]);
  const { data: allNotifications = [], refetch: refetchAllNotifications } =
    useGetAllNotificationsQuery();
  const { data: initialSelected = [], refetch: refetchSelectedNotifications } =
    useGetNotificationsByDeviceIdQuery(device.id);
  const [alreadySelected, setAlreadySelected] = useState([]);
  const [addPermission] = useAddPermissionMutation();
  const [deletePermission] = useDeletePermissionMutation();

  useEffect(() => {
    refetchAllNotifications();
  }, []);

  useEffect(() => {
    if (initialSelected.length > 0) {
      setAlreadySelected(initialSelected);
      setSelectedNotification(initialSelected.map((noti) => noti.id));
    }
  }, [initialSelected]);

  const formik = useFormik({
    initialValues: { deviceId: device.id, notificationId: [] },
    validationSchema: Yup.object({
      notificationId: Yup.array().required(
        "At least one notification is required"
      ),
    }),
    onSubmit: async () => {
      try {
        for (const id of selectedNotification) {
          const body = { deviceId: device.id, notificationId: id };
          await addPermission(body).unwrap();
        }
        await refetchSelectedNotifications();
        toast.success("New Notification is linked to the device", {
          autoClose: 800,
        });
      } catch (error) {
        toast.error(
          "Error linking the Notification to device, please try again later",
          { autoClose: 800 }
        );
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("notificationId", selectedNotification);
  }, [selectedNotification]);

  const toggleNotification = (optionId) => {
    const selectedNoti = allNotifications.find((noti) => noti.id === optionId);
    setSelectedNotification((prev) =>
      prev.includes(optionId)
        ? prev.filter((id) => id !== optionId)
        : [...prev, optionId]
    );
    if (selectedNoti) {
      setAlreadySelected((prev) =>
        prev.some((noti) => noti.id === optionId)
          ? prev.filter((noti) => noti.id !== optionId)
          : [...prev, selectedNoti]
      );
    }
  };

  const handleDelete = async (notiId) => {
    const body = { deviceId: `${device.id}`, notificationId: notiId };
    try {
      await deletePermission(body).unwrap();
      setAlreadySelected((prev) => prev.filter((noti) => noti.id !== notiId));
      setSelectedNotification((prev) => prev.filter((id) => id !== notiId));
      await refetchSelectedNotifications();
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  const filteredNotifications = allNotifications.filter(
    (noti) =>
      !alreadySelected.some((selectedNoti) => selectedNoti.id === noti.id)
  );

  if (title !== "set notification") return <></>;

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="pb-3">
        <p>Selected Notifications</p>
        <div className="Tag-container">
          {alreadySelected.length === 0 && (
            <div className=" w-100 flex-row justify-center">
              There is no selected notifications
            </div>
          )}
          {alreadySelected.map((noti) => (
            <div
              className="Tag"
              key={noti.id}
              onClick={() => handleDelete(noti.id)}
            >
              <Close className="close-10" img="Close" />
              <p>{noti.type}</p>
            </div>
          ))}
        </div>
        <div className="flex-row">
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="border-black"
              id="dropdown-basic"
            >
              Select Notification
            </Dropdown.Toggle>
            <Dropdown.Menu className="absolute-dropdown">
              {filteredNotifications.map((option) => (
                <Dropdown.Item
                  key={option.id}
                  onClick={() => toggleNotification(option.id)}
                  active={selectedNotification.includes(option.id)}
                >
                  {option.type}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="w-100 flex-row justify-end">
          <Button
            text="Save"
            type="submit"
            style="button fs-14 p-6-12 btn-success mt-12"
          />
        </div>
      </div>
    </form>
  );
};

export default SetNotification;
