import React, { useContext, useEffect, useState } from "react";
import SecondHeader from "../Header/SecondHeader";
import VehicleHead from "./VehiclesParts/VehicleHead";
import { popupcontext } from "../../context/Popupscontext";
import { Helmet } from "react-helmet";
import Pagination from "../Helpers/Pagination/Pagination";
import Loader from "../Helpers/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { addVehicles } from "../../Redux/service/Vehicles/VehiclesSlice";
import { filtrationcontext } from "../../context/Filtercontext";
import { useGetAllCustomVehiclesQuery } from "../../Redux/service/Vehicles/CustomVehicles";
import { VehicleForm } from "./VehicleForm/VehicleForm";
import VehiclesTable from "./VehiclesTable/VehiclesTable";

export default function Vehicles() {
	const { handleShow, setEdited } = useContext(popupcontext);
	const {
		limit,
		setLimit,
		totalPages,
		Sort,
		setSort: SetSortFilter,
	} = useContext(filtrationcontext);
	const [loading, setLoading] = useState(false);
	const [sort, setSort] = useState({
		by: "id",
		order: "desc",
	});
	const dispatch = useDispatch();
	useEffect(() => {
		return () => SetSortFilter({ type: "id", value: null });
	}, [SetSortFilter]);

	useEffect(() => {
		if (Sort.type === "name") {
			setSort({
				by: "name",
				order: Sort.value ? "desc" : "asc",
			});
		} else if (Sort.type === "id") {
			setSort({
				by: "id",
				order: "desc",
			});
		} else if (Sort.type === "plate number") {
			setSort({
				by: "plateNumber",
				order: Sort.value ? "desc" : "asc",
			});
		} else if (Sort.type === "status") {
			setSort({
				by: "status",
				order: Sort.value ? "desc" : "asc",
			});
		} else if (Sort.type === "imei") {
			setSort({
				by: "imei",
				order: Sort.value ? "desc" : "asc",
			});
		} else if (Sort.type === "last update") {
			setSort({
				by: "lastUpdate",
				order: Sort.value ? "desc" : "asc",
			});
		} else if (Sort.type === "parent") {
			setSort({
				by: "parent",
				order: Sort.value ? "desc" : "asc",
			});
		}
	}, [Sort.value, Sort.type]);

	const [currentPage, setCurrentPage] = useState(
		Number(localStorage.getItem("page")) || 1
	);

	const {
		data: vehiclesData,
		isLoading,
		refetch,
	} = useGetAllCustomVehiclesQuery(
		{ page: currentPage, limit: limit, sortby: sort.by, sortorder: sort.order  },
		{
			skip: !currentPage || !limit || !sort.by || !sort.order,
		}
	);

	useEffect(() => {
		setLimit(10);
	}, [setLimit]);

	useEffect(() => {
		return () => {
			console.log("Vehicles component unmounted");
			setSort({ by: null, order: null });
			localStorage.setItem("page", 1);
		};
	}, [setSort]);

	useEffect(() => {
		if (vehiclesData) {
			dispatch(addVehicles(vehiclesData.vehicles));
			if (vehiclesData.vehicles.length === 0 && currentPage > 1) {
				setCurrentPage(currentPage - 1);
			}
		}
		setLoading(false);
	}, [vehiclesData, dispatch, currentPage, refetch]);

	useEffect(() => {
		localStorage.setItem("page", currentPage);
	}, [currentPage]);

	const handlePageClick = (data) => {
		const selectedPage = data.selected + 1;
		setCurrentPage(selectedPage);
	};

	const handleSuccess = (newVehicle) => {
		if (vehiclesData) {
			const updatedVehicles = [newVehicle, ...vehiclesData.vehicles].slice(
				0,
				limit
			);
			dispatch(addVehicles(updatedVehicles));
			setLoading(true);
			refetch();
		}
	};

	const handleEdit = (updatedVehicle) => {
		if (vehiclesData) {
			dispatch(
				addVehicles(
					vehiclesData.vehicles.map((vehicle) =>
						vehicle.id === updatedVehicle.id ? updatedVehicle : vehicle
					)
				)
			);
			setEdited(true);
			refetch();
		}
	};
	const inf = useSelector((state) => state.users.user);
	return (
		<>
			<Helmet>
				<title>Vehicles</title>
				<meta name="description" content="Vehicles" />
			</Helmet>
			{(inf.administrator === true ||
				(inf.administrator === false &&
					(inf.deviceLimit > 0 || inf.deviceLimit === -1))) && (
				<>
					<SecondHeader
						title="Vehicles"
						add="Add new vehicle"
						onClick={() => handleShow("addForm")}
					/>
				</>
			)}

			<VehicleHead />
			{isLoading || loading ? (
				<>
					<div className="loader-container loader-table">
						<Loader />
					</div>
				</>
			) : (
				<>
					{/**<Table
            HeadField={HeadField}
            BodyData={vehiclesData?.vehicles}
            type="vehicles"
          />**/}
					<VehiclesTable BodyData={vehiclesData?.vehicles} type="vehicles" />
					<Pagination
						handlePageClick={handlePageClick}
						pageCount={
							totalPages
								? Math.ceil(totalPages / limit)
								: Math.ceil(vehiclesData?.totalPages)
						}
					/>
				</>
			)}
			<VehicleForm onSuccess={handleSuccess} onEdit={handleEdit} />
		</>
	);
}
