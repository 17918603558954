import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const DevicesApi = createApi({
  reducerPath: "Devices",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/vehicles/user/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllDevices: builder.query({
      query: () => "/uniquedevices",
    }),
    getSpecificDevice: builder.query({
      query: (deviceId) => `/uniquedevices?id=${deviceId}`,
    }),
    getUsersDevice: builder.query({
      query: (userId) => `/uniquedevices?userId=${userId}`,
    }),
  }),
});

export const {
  useGetAllDevicesQuery,
  useGetSpecificDeviceQuery,
  useGetUsersDeviceQuery,
  useLazyGetUsersDeviceQuery,
} = DevicesApi;
