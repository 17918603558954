import React, { useContext } from "react";
import { popupcontext } from "../../../context/Popupscontext";
import OffcanvasFooter from "../../Helpers/Offcanvasfooter/OffcanvasFooter";
import Filter from "../../Helpers/Filter/Filter";
import { Offcanvas } from "react-bootstrap";
import FormHeader from "../../Helpers/Form/FormParts/FormHeader";
import EventsInput from "../EventsInput/EventsInput";

export default function EventsFilter({
	setStartDate,
	startDate,
	setEndDate,
	endDate,
	selectedUser,
	selectedId,
	setSelectedUser,
	setSelectedId,
	setIsShown,
	isShown,
}) {
	const { form } = useContext(popupcontext);

	return (
		<>
			<Filter show={form}>
				<Offcanvas.Header>
					<FormHeader title={"Events Filter"} />
				</Offcanvas.Header>
				<div className="H-line"></div>
				<Offcanvas.Body>
					<EventsInput
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
						selectedUser={selectedUser}
                        selectedId={selectedId}
						setSelectedUser={setSelectedUser}
						setSelectedVehicles={setSelectedId}
					/>
					<OffcanvasFooter
						text="Show"
						isShown={isShown}
						setIsShown={setIsShown}
						val={endDate}
					/>
				</Offcanvas.Body>
			</Filter>
		</>
	);
}
