import React from "react";

export default function Empty({ title, subtitle }) {
  return (
    <div className="empty-container">
      <div className="empty-inner">
        <img
          src={`${process.env.PUBLIC_URL}/assets/Empty.svg`}
          alt="No Data Found"
        />
        <h3 className="empty-title">{title}</h3>
        <p className="empty-subtitle">{subtitle}</p>
      </div>
    </div>
  );
}
