import React, { useContext, useEffect, useState } from "react";
import Popup from "../Helpers/Popup/Popup";
import Loader from "../Helpers/Loader/Loader";
import { Modal } from "react-bootstrap";
import PopupTitle from "../Helpers/Popup/PopupParts/PopupTitle";
import Close from "../Helpers/CloseBtn/Close";
import { popupcontext } from "../../context/Popupscontext";
import { useGetSpecificPositionQuery } from "../../Redux/service/Positions";
import PopupBodyItem from "../Helpers/Popup/PopupParts/PopupBodyItem";
import axios from "axios";
import { formatDate } from "../../JsHelpers/DateFormat";
import { useLazyGetSpecificVehicleQuery } from "../../Redux/service/Vehicles/Vehicles";
import { useLazyGetSpecificDriverQuery } from "../../Redux/service/Drivers/Drivers";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";

export default function NotificationPopup({ positionId }) {
	const { displayAlert, setDisplayAlert } = useContext(popupcontext);
	const [geoCodes, setGeoCodes] = useState({});
	const { data, isLoading } = useGetSpecificPositionQuery(positionId, {
		skip: !positionId,
	});
	const [getVehicle, { data: vehicleData }] = useLazyGetSpecificVehicleQuery();
	const [getDriver, { data: driverData }] = useLazyGetSpecificDriverQuery();
	const [vehicleNames, setVehicleNames] = useState("");
	const [driverNames, setDriverNames] = useState("");

	const close = () => {
		setDisplayAlert(null);
	};

	const fetchGeoCodePosition = async (lat, lon, id) => {
		try {
			const response = await axios.get(
				`https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lon}&zoom=18&format=jsonv2`
			);
			setGeoCodes((prev) => ({ ...prev, [id]: response.data.display_name }));
		} catch (error) {
			console.error("Error fetching geocode position:", error);
		}
	};
	useEffect(() => {
		if (data) {
			data.forEach((i) => {
				// Fetch geocode if not already fetched
				if (i.latitude && i.longitude && !geoCodes[i.id]) {
					fetchGeoCodePosition(i.latitude, i.longitude, i.id);
				}
				if (i.deviceId) {
					getVehicle(i.deviceId).then((res) => {
						if (res?.data) {
							setVehicleNames(res?.data.data.name);
							if (res?.data.data.driverId) {
								getDriver(res?.data.data.driverId).then((res) => {
									setDriverNames(res?.data.data.name);
								});
							} else {
								setDriverNames("--------");
							}
						}
					});
				}
			});
		}
	}, [data, geoCodes, vehicleNames, getVehicle]);

	const truncateItem = (text) => {
		const maxLength = 50;
		return text?.length > maxLength
			? text.substring(0, maxLength) + "..."
			: text;
	};
	function knotsToKmPerHour(knots) {
		return knots * 1.852;
	}
	const customIcon = new Icon({
		iconUrl: "https://www.svgrepo.com/show/513317/location-pin.svg",
		iconSize: [32, 32],
		iconAnchor: [16, 32],
		popupAnchor: [0, -32],
	});
	return (
		<Popup show={displayAlert}>
			{isLoading && (
				<div className="loader-container">
					<Loader />
				</div>
			)}
			<Modal.Header className="modal-header flex-between">
				<Modal.Title id="contained-modal-title-vcenter">
					<PopupTitle headtitle="Notification" />
				</Modal.Title>
				<Close style="close-32 close" close={close} img="Close" />
			</Modal.Header>
			<Modal.Body className="modal-body-scrollable">
				<div className="display-border">
					{data?.map((i) => {
						const { formattedDate, formattedTime } = formatDate(i?.fixTime);
						return (
							<div key={i.id}>
								<PopupBodyItem
									title="Vehicle Name"
									item={vehicleNames || "Loading name..."}
								/>
								{/**<PopupBodyItem
									title="Driver Name"
									item={driverNames || "Loading name..."}
								/>**/}
								<PopupBodyItem
									title="Speed"
									item={`${Math.round(knotsToKmPerHour(i.speed))} Km/h`}
								/>
								<PopupBodyItem
									title="Date"
									item={formattedDate + " " + formattedTime}
								/>
								<PopupBodyItem
									title="Location"
									item={truncateItem(geoCodes[i.id]) || "Loading address..."}
									subitem={geoCodes[i.id]}
								/>
								<MapContainer
									center={i ? [i.latitude, i.longitude] : [23.8859, 45.0792]} // Update center when position changes
									zoom={33}
									style={{
										marginBlock: "15px",
										height: "250px",
										width: "100%",
										borderRadius: "8px",
									}}
								>
									<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
									{i.latitude && i.longitude && (
										<Marker
											position={[i.latitude, i.longitude]}
											icon={customIcon}
										/>
									)}
								</MapContainer>
							</div>
						);
					})}
				</div>
			</Modal.Body>
		</Popup>
	);
}
