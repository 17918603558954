import React from "react";
import VehicleTableHeaders from "./VehicleTableHeaders";
import VehiclesTableBody from "../VehiclesTableBody/VehiclesTableBody";

export default function VehiclesTable({ BodyData }) {
	return (
		<div className="bg-white-rounded-top mt-3 table-parent">
			<div className={`table`}>
				<VehicleTableHeaders />
				<div className="table-body">
					<VehiclesTableBody data={BodyData} />
				</div>
			</div>
		</div>
	);
}
