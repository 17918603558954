import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const NotificationsApi = createApi({
	reducerPath: "Notifications",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_URL}`,
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("token");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (builder) => ({
		getAllNotifications: builder.query({
			query: () => "/notifications",
		}),
		getSpecificNotifications: builder.query({
			query: (id) => `notifications/${id}`,
		}),
		getNotificationsByDeviceId: builder.query({
			query: (id) => `/notifications?deviceId=${id}`,
		}),
		getEvents: builder.query({
			query: ({ from: startdate, to: enddate, selectedid: id }) =>
				`/reports/events?deviceId=${id}&from=${startdate}&to=${enddate}&type=allEvents`,
		}),
		addNotification: builder.mutation({
			query: (val) => ({
				url: `/notifications`,
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: val,
			}),
		}),
		editNotification: builder.mutation({
			query: ({ id, val }) => ({
				url: `/notifications/${id}`,
				method: "PUT",
				headers: { "Content-Type": "application/json" },
				body: val,
			}),
		}),
		deleteNotification: builder.mutation({
			query: (id) => ({
				url: `/notifications/${id}`,
				method: "DELETE",
				headers: { "Content-Type": "application/json" },
				body: id,
			}),
		}),
	}),
});

export const {
	useGetAllNotificationsQuery,
	useGetSpecificNotificationsQuery,
	useGetNotificationsByDeviceIdQuery,
	useGetEventsQuery,
	useAddNotificationMutation,
	useEditNotificationMutation,
	useDeleteNotificationMutation,
} = NotificationsApi;
