import React from "react";
import DriverTableBody from "../DriverTableBody/DriverTableBody";
 import DriversTableHead from "./DriversTableHead";

export default function DriversTable({ BodyData }) {
	return (
		<div className="bg-white-rounded-top mt-3 table-parent">
			<div className={`table`}>
				<DriversTableHead />
				<div className="table-body">
					<DriverTableBody data={BodyData} />
				</div>
			</div>
		</div>
	);
}
