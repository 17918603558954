import React, { useContext, useEffect, useState } from "react";
import ObjectSelected from "../../ObjectSelected/ObjectSelected";
import { popupcontext } from "../../../../context/Popupscontext";
import { useGetUsersDeviceQuery } from "../../../../Redux/service/Devices";
import { formatDate } from "../../../../JsHelpers/DateFormat";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import Loader from "../../../Helpers/Loader/Loader";
import { useLocation } from "react-router-dom";
import Delete from "../../../Helpers/Delete/DeletePopup";
import { useGetUsersGeofencesQuery } from "../../../../Redux/service/Geofences/Geofences";
import Display from "../../../Helpers/Table/Table Actions/Display";
import { draggablecontext } from "../../../../context/Draggablecontext";
import { filtrationcontext } from "../../../../context/Filtercontext";

export default function ObjectTableRows({ userid, setUserDevices }) {
	let {
		handleShowObject,
		handleShow,
		object,
		objectselect,
		setObjectselect,
		setHistoryPlayback,
		handleEditForm,
		refetchGeofences,
		setRefetchGeofences,
	} = useContext(popupcontext);
	let {
		GeofencessearchQuery,
		filter,
		onlineVehicles,
		offlineVehicles,
		unknownVehicles,
		SpeedSort,
		NameSort,
		LastUpdateSort,
		StatusSort,
	} = useContext(filtrationcontext);
	let { handleFocusClick } = useContext(draggablecontext);
	let { pathname } = useLocation();
	const [selectedId, setSelectedId] = useState(null);
	const [imei, setImei] = useState(null);
	const [shownData, setShownData] = useState([]);
	const { ObjectsearchQuery } = useContext(filtrationcontext);
	let { data, isLoading } = useGetUsersDeviceQuery(userid);
	const { data: Geofences, refetch } = useGetUsersGeofencesQuery(userid, {
		skip: !userid,
	});
	const positions = useSelector((state) => state.webSocket.positions);
	const devices = useSelector((state) => state.webSocket.devices);
	const map = useMap();

	let online = data?.filter((i) => {
		return i.status === "online";
	});
	let offline = data?.filter((i) => {
		return i.status === "offline";
	});
	let unknown = data?.filter((i) => {
		return i.status === "unknown";
	});
	let AscnameSort = data
		?.filter((i) => /^[A-Za-z]/.test(i.name))
		.sort((a, b) => a.name.localeCompare(b.name));
	let DescnameSort = data
		?.filter((i) => /^[A-Za-z]/.test(i.name))
		.sort((a, b) => b.name.localeCompare(a.name));
	let AscLastUpdateSort = data?.slice().sort((a, b) => {
		if (!a.lastUpdate) return 1;
		if (!b.lastUpdate) return -1;
		return new Date(a.lastUpdate) - new Date(b.lastUpdate);
	});

	let DescLastUpdateSort = data?.slice().sort((a, b) => {
		if (!a.lastUpdate) return 1;
		if (!b.lastUpdate) return -1;
		return new Date(b.lastUpdate) - new Date(a.lastUpdate);
	});

	let AscSpeedSort = data?.slice().sort((a, b) => {
		const speedA = positions.find((p) => p.deviceId === a.id)?.speed || 0;
		const speedB = positions.find((p) => p.deviceId === b.id)?.speed || 0;
		return speedA - speedB;
	});

	let DescSpeedSort = data?.slice().sort((a, b) => {
		const speedA = positions.find((p) => p.deviceId === a.id)?.speed || 0;
		const speedB = positions.find((p) => p.deviceId === b.id)?.speed || 0;
		return speedB - speedA;
	});

	let OnlineStatusSort = data?.slice().sort((a, b) => {
		if (a.status === "online" && b.status !== "online") return -1;
		if (a.status !== "online" && b.status === "online") return 1;
		return 0; // Keep the order for non-online statuses
	  });
	  
	  let OfflineStatusSort = data?.slice().sort((a, b) => {
		if (a.status === "offline" && b.status !== "offline") return -1;
		if (a.status !== "offline" && b.status === "offline") return 1;
		return 0; // Keep the order for non-offline statuses
	  });
	  
	useEffect(() => {
		let filteredData = [];
		if (!ObjectsearchQuery) {
			if (onlineVehicles) {
				filteredData = online;
			} else if (offlineVehicles) {
				filteredData = offline;
			} else if (unknownVehicles) {
				filteredData = unknown;
			}
			if (LastUpdateSort !== null) {
				filteredData = filteredData.length ? filteredData : data;
				filteredData = LastUpdateSort ? DescLastUpdateSort : AscLastUpdateSort;
			} else if (NameSort !== null) {
				filteredData = filteredData.length ? filteredData : data;
				filteredData = NameSort ? DescnameSort : AscnameSort;
			} else if (SpeedSort !== null) {
				filteredData = filteredData.length ? filteredData : data;
				filteredData = SpeedSort ? AscSpeedSort : DescSpeedSort;
			} else if (StatusSort !== null) {
				filteredData = filteredData.length ? filteredData : data;
				filteredData = StatusSort ? OnlineStatusSort : OfflineStatusSort;
			}
		} else {
			filteredData = data?.filter((devices) => {
				const matchesSearch = ObjectsearchQuery
					? devices.name.toLowerCase().includes(ObjectsearchQuery.toLowerCase())
					: true;
				return matchesSearch;
			});
		}
		setShownData(filteredData);
	}, [
		ObjectsearchQuery,
		data,
		filter,
		onlineVehicles,
		offlineVehicles,
		unknownVehicles,
		NameSort,
		LastUpdateSort,
		SpeedSort,
		StatusSort,
	]);

	const getFormattedDate = (serverTime) => formatDate(serverTime);

	const handleRowClick = (id, lat, lon, imei) => {
		setObjectselect(id === selectedId ? !objectselect : true);
		setSelectedId(id);
		handleShowObject();
		const targetCoordinates = lat && lon ? [lat, lon] : [33, 30];
		map.setView(targetCoordinates, 13);
		setImei(imei);
		setHistoryPlayback({ state: true, Id: id });
	};

	const handleDeleteClick = (GeoId) => {
		if (GeoId) {
			handleShow("deleteItem", "geofences", GeoId);
		}
	};
	const parseWKTPolygon = (wkt) => {
		const cleanedWKT = wkt.replace("POLYGON ((", "").replace("))", "");
		const coordinates = cleanedWKT.split(", ").map((coord) => {
			const [lng, lat] = coord.split(" ").map(Number);
			return [lng, lat];
		});
		return coordinates;
	};
	useEffect(() => {
		if (refetchGeofences) {
			refetch();
			setRefetchGeofences(false);
		}
	}, [refetchGeofences, refetch, setRefetchGeofences]);
	const filteredGeofences = Geofences?.filter((geofence) =>
		geofence.name.toLowerCase().includes(GeofencessearchQuery.toLowerCase())
	);
	if (isLoading) return <Loader />;
	if (pathname === "/geofences") {
		if (filteredGeofences?.length === 0) {
			return (
				<>
					<div className="my-5 p-5">
						<div className="text-center text-success fs-16 fw-700">
							No data shown
						</div>
					</div>
				</>
			);
		}
	}
	function knotsToKmPerHour(knots) {
		return knots * 1.852;
	}
	return (
		<>
			{pathname === "/livetracking" && (
				<>
					{shownData?.map((i) => {
						const position = positions.find(
							(position) => position.deviceId === i.id
						);
						const device = devices.find((device) => device.id === i.id);
						const speed = position ? `${Math.round(position.speed)}` : 0;
						const { formattedDate, formattedTime } = getFormattedDate(
							position?.serverTime
						);

						return (
							<div key={i.id}>
								<div
									className="object-table object-table-row"
									onClick={() => {
										handleRowClick(
											i.id,
											position?.latitude ?? 24.7136,
											position?.longitude ?? 46.6753,
											i?.uniqueId
										);
									}}
								>
									<div className="table-checkbox">
										<input
											type="checkbox"
											checked={
												(object && i.id === selectedId) ||
												(object && objectselect && i.id === selectedId)
											}
											onChange={() => {}}
										/>
									</div>
									<div className="fs-14 fw-400 object-table-element w-84">
										{i.name}
									</div>
									<div className="fs-14 fw-400 object-table-element">
										{device?.status === "online" &&
										position?.attributes.motion !== false
											? Math.round(knotsToKmPerHour(speed))
											: 0}{" "}
										Km/h
									</div>
									<div
										className="fs-14 fw-400 object-table-element"
										style={{ width: "80px" }}
									>
										<p className="fs-14 fw-400 object-table-element m-0">
											{formattedDate !== "Invalid Date" ? formattedDate : "N/A"}
										</p>
										<p className="fs-14 fw-400 object-table-element m-0">
											{formattedTime !== "Invalid Date" ? formattedTime : ""}
										</p>
									</div>
									<div className="object-table-element">
										<div
											className={
												device?.status === "online"
													? "status-online"
													: "status-offline"
											}
										></div>
									</div>
									<div
										className="object-table-element object-eye-focus "
										onClick={(e) => {
											e.stopPropagation();
											handleFocusClick(i.id, i?.uniqueId, i);
										}}
									>
										<img
											src="/assets/Eye.svg"
											alt="Eye Icon"
											title="Multi-view"
										/>
									</div>
								</div>
								{object && imei === i?.uniqueId ? (
									<ObjectSelected imei={imei} device={i} position={position} />
								) : null}
							</div>
						);
					})}
				</>
			)}
			{pathname === "/geofences" && (
				<>
					{filteredGeofences?.length > 0 &&
						filteredGeofences.map((i) => {
							const coordinates = parseWKTPolygon(i.area);
							return (
								<div key={i.id}>
									<div
										className="object-table object-table-row"
										onClick={() => map.setView(coordinates[2], 20)}
									>
										<div className="table-checkbox">
											<input
												type="checkbox"
												checked={
													(object && i.id === selectedId) ||
													(object && objectselect && i.id === selectedId)
												}
												onChange={() => {}}
											/>
										</div>
										<div className="fs-14 fw-400 object-table-element w-50">
											{i.name}
										</div>
										<div className="object-table-element d-flex">
											<Display GeoId={i.id} type="geofences" />
											<img
												role="button"
												src="/assets/Edit.svg"
												alt="edit"
												onClick={() => {
													handleEditForm("geofences", i.id);
												}}
											/>
											<Delete
												GeoId={i.id}
												handleDeleteClick={() => {
													handleDeleteClick(i.id);
												}}
											/>
										</div>
									</div>
								</div>
							);
						})}
				</>
			)}
		</>
	);
}
