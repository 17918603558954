import React, { createContext, useState } from "react";
export const filtrationcontext = createContext();

export default function Filtercontext({ children }) {
	let [checked, setChecked] = useState([]);
	let [parentCheck, setParentCheck] = useState([]);
	let [childCheck, setChildCheck] = useState([]);
	const [filter, setFilter] = useState(false);
	let [filteredData, setFilteredData] = useState([]);
	let [userData, setUserData] = useState();
	let [checkValues, setcheckValues] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	const [ObjectsearchQuery, setObjectSearchQuery] = useState("");
	const [ObjectUsersearchQuery, setObjectUserSearchQuery] = useState("");
	const [VehiclessearchQuery, setVehiclesSearchQuery] = useState("");
	const [DriverssearchQuery, setDriversSearchQuery] = useState("");
	const [UserssearchQuery, setUsersSearchQuery] = useState("");
	const [onlineVehicles, setOnlineVehicles] = useState(false);
	const [offlineVehicles, setOfflineVehicles] = useState(false);
	const [unknownVehicles, setUnkownVehicles] = useState(false);
	const [NameSort, setNameSort] = useState(null);
	const [LastUpdateSort, setLastUpdateSort] = useState(true);
	const [SpeedSort, setSpeedSort] = useState(false);
	const [StatusSort, setStatusSort] = useState(null);
	const [Sort, setSort] = useState({ type: "", value: null });
	const [GeofencessearchQuery, setGeofencesSearchQuery] = useState("");
	const [reportTypessearchQuery, setReportTypesSearchQuery] = useState("");
	const [AlertsearchQuery, setAlertSearchQuery] = useState("");
	const [NotifictionSearchQuery, setNotificationSearchQuery] = useState("");
	const [EventsSearchQuery, setEventSearchQuery] = useState("");

	const [limit, setLimit] = useState(10);
	const [totalPages, setTotalPages] = useState();
	const handleFilter = () => setFilter(true);
	return (
		<>
			<filtrationcontext.Provider
				value={{
					checked,
					setChecked,
					filter,
					setFilter,
					handleFilter,
					checkValues,
					setcheckValues,
					searchQuery,
					setSearchQuery,
					ObjectsearchQuery,
					setObjectSearchQuery,
					ObjectUsersearchQuery,
					setObjectUserSearchQuery,
					VehiclessearchQuery,
					setVehiclesSearchQuery,
					DriverssearchQuery,
					setDriversSearchQuery,
					UserssearchQuery,
					setUsersSearchQuery,
					GeofencessearchQuery,
					setGeofencesSearchQuery,
					reportTypessearchQuery,
					setReportTypesSearchQuery,
					parentCheck,
					setParentCheck,
					childCheck,
					setChildCheck,
					filteredData,
					setFilteredData,
					userData,
					setUserData,
					limit,
					setLimit,
					totalPages,
					setTotalPages,
					AlertsearchQuery,
					setAlertSearchQuery,
					NotifictionSearchQuery,
					setNotificationSearchQuery,
					EventsSearchQuery,
					setEventSearchQuery,
					onlineVehicles,
					setOnlineVehicles,
					offlineVehicles,
					setOfflineVehicles,
					unknownVehicles,
					setUnkownVehicles,
					NameSort,
					setNameSort,
					LastUpdateSort,
					setLastUpdateSort,
					SpeedSort,
					setSpeedSort,
					StatusSort,
					setStatusSort,

					Sort,
					setSort,
				}}
			>
				{children}
			</filtrationcontext.Provider>
		</>
	);
}
