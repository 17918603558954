import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { filtrationcontext } from "../../../../context/Filtercontext";

export default function ObjectTableHead() {
	let { pathname } = useLocation();
	const {
		NameSort,
		setNameSort,
		LastUpdateSort,
		setLastUpdateSort,
		SpeedSort,
		setSpeedSort,
		StatusSort,
		setStatusSort,
	} = useContext(filtrationcontext);
	return (
		<>
			<div className="object-table">
				<div className="table-checkbox">
					<input type="checkbox" name="" id="" />
				</div>
				{pathname === "/livetracking" ? (
					<>
						<div
							className="fs-14 fw-600 object-table-element d-flex cursor"
							onClick={() => {
								setNameSort(!NameSort);
								setLastUpdateSort(null);
								setSpeedSort(null);
								setStatusSort(null);
							}}
						>
							<div>Vehicle Name</div>
							<img
								className="sortimg"
								src={
									NameSort
										? `${process.env.PUBLIC_URL}/assets/DescendingSort.svg`
										: `${process.env.PUBLIC_URL}/assets/AscendingSort.svg`
								}
								alt="sort"
							/>
						</div>
						<div
							className="fs-14 fw-600 object-table-element d-flex  cursor"
							onClick={() => {
								setSpeedSort(!SpeedSort);
								setNameSort(null);
								setLastUpdateSort(null);
								setStatusSort(null);
							}}
						>
							<div>Speed</div>
							<img
								className="sortimg"
								src={
									SpeedSort
										? `${process.env.PUBLIC_URL}/assets/DescendingSort.svg`
										: `${process.env.PUBLIC_URL}/assets/AscendingSort.svg`
								}
								alt="sort"
							/>
						</div>
						<div
							className="fs-14 fw-600 object-table-element d-flex cursor"
							onClick={() => {
								setLastUpdateSort(!LastUpdateSort);
								setNameSort(null);
								setSpeedSort(null);
								setStatusSort(null);
							}}
						>
							<div>Last Update</div>
							<img
								className="sortimg"
								src={
									LastUpdateSort
										? `${process.env.PUBLIC_URL}/assets/DescendingSort.svg`
										: `${process.env.PUBLIC_URL}/assets/AscendingSort.svg`
								}
								alt="sort"
							/>
						</div>
						<div
							className="fs-14 fw-600 object-table-element d-flex  cursor"
							onClick={() => {
								setStatusSort(!StatusSort);
								setNameSort(null);
								setLastUpdateSort(null);
								setSpeedSort(null);
							}}
						>
							<div>Status</div>
							<img
								className="sortimg"
								src={
									StatusSort
										? `${process.env.PUBLIC_URL}/assets/DescendingSort.svg`
										: `${process.env.PUBLIC_URL}/assets/AscendingSort.svg`
								}
								alt="sort"
							/>
						</div>
						<div className="fs-14 fw-600 object-table-element">Actions</div>
					</>
				) : (
					<>
						<div className="fs-14 fw-600 object-table-element w-50">
							Geofence Name
						</div>
						<div className="fs-14 fw-600 object-table-element">Actions</div>
					</>
				)}
			</div>
		</>
	);
}
