import React from "react";
import AlertsComponent from "./DashboardAlerts/AlertsComponent";
import { useGetAllAlertQuery } from "../../../Redux/service/Alerts/Alerts";
import AlertsSkeleton from "./DashboardAlerts/SkeletonAlertsComponent";

export default function DashboardAlerts() {
  const { data, isLoading, isError } = useGetAllAlertQuery();

  if (isLoading)
    return (
      <div className="flex-between">
        <AlertsSkeleton />
        <AlertsSkeleton />
        <AlertsSkeleton />
        <AlertsSkeleton />
        <AlertsSkeleton />
        <AlertsSkeleton />
      </div>
    );
  if (isError)
    return (
      <div className="flex-between">
        <AlertsComponent title="SOS Alerts" number={0} image="SosAlert" />
        <AlertsComponent title="Speed Alerts" number={0} image="SpeedAlert" />
        <AlertsComponent title="Idle Alerts" number={0} image="IdleAlert" />
        <AlertsComponent title="Power Cut" number={0} image="Powercut" />
        <AlertsComponent title="Geofences" number={0} image="GeofenceAlert" />
        <AlertsComponent title="Other Alerts" number={0} image="OtherAlert" />
      </div>
    );
  return (
    <>
      {data?.alerts && (
        <div className="flex-between">
          <AlertsComponent
            title="Total Alerts"
            percent={
              data.alerts.totalAlertsDifference > 0
                ? `+${data.alerts.totalAlertsDifference}`
                : data.alerts.totalAlertsDifference < 0
                ? `${data.alerts.totalAlertsDifference}`
                : ""
            }
            number={data.alerts.totalAlerts}
            image="SosAlert"
          />
          <AlertsComponent
            title="Speed Alerts"
            percent={
              data.alerts.speedAlertsDifference > 0
                ? `+${data.alerts.speedAlertsDifference}`
                : data.alerts.speedAlertsDifference < 0
                ? `${data.alerts.speedAlertsDifference}`
                : ""
            }
            number={data.alerts.speedAlerts}
            image="SpeedAlert"
          />
          <AlertsComponent
            title="Idle Alerts"
            percent={
              data.alerts.idleAlertsDifference > 0
                ? `+${data.alerts.idleAlertsDifference}`
                : data.alerts.idleAlertsDifference < 0
                ? `${data.alerts.idleAlertsDifference}`
                : ""
            }
            number={data.alerts.idleAlerts}
            image="IdleAlert"
          />
          <AlertsComponent
            title="Power Cut"
            percent={
              data.alerts.powerCutAlertsDifference > 0
                ? `+${data.alerts.powerCutAlertsDifference}`
                : data.alerts.powerCutAlertsDifference < 0
                ? `${data.alerts.powerCutAlertsDifference}`
                : ""
            }
            number={data.alerts.powerCutAlerts}
            image="PowerCut"
          />
          <AlertsComponent
            title="Geofences"
            percent={
              data.alerts.geofenceAlertsDifference > 0
                ? `+${data.alerts.geofenceAlertsDifference}`
                : data.alerts.geofenceAlertsDifference < 0
                ? `${data.alerts.geofenceAlertsDifference}`
                : ""
            }
            number={data.alerts.geofenceAlerts}
            image="GeofenceAlert"
          />
          <AlertsComponent
            title="Other Alerts"
            percent={
              data.alerts.otherAlertsDifference > 0
                ? `+${data.alerts.otherAlertsDifference}`
                : data.alerts.otherAlertsDifference < 0
                ? `${data.alerts.otherAlertsDifference}`
                : ""
            }
            number={data.alerts.otherAlerts}
            image="OtherAlert"
          />
        </div>
      )}
    </>
  );
}
