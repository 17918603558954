import React, { useEffect, useState } from "react";
import SecondHeader from "../Header/SecondHeader";
import EventsHead from "./EventsParts/EventsHead";
import { useSelector } from "react-redux";
import Table from "../Helpers/Table/Table";
import { useGetEventsQuery } from "../../Redux/service/Notifications/Notifications";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export default function Events() {
	const inf = useSelector((state) => state.users.user);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [selectedUser, setSelectedUser] = useState(inf.id);
	const [selectedId, setSelectedId] = useState("");
	const [isShown, setIsShown] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [event, setEvent] = useState([]);
	let HeadField = ["Type", "Date", "Data"];

	let { data, refetch } = useGetEventsQuery(
		{ from: startDate, to: endDate, selectedid: selectedId },
		{
			skip: !startDate || !endDate || !selectedId || !isShown,
		}
	);

	useEffect(() => {
		if (isShown) {
			// Validate input fields
			if (!startDate || !endDate || !selectedId) {
				toast.error("Please select at least one date and a vehicle", {
					autoClose: 800,
				});
				setIsShown(false);
				return;
			}

			// Trigger loading state
			setIsLoading(true);
			setEvent([]); // Clear previous data while loading

			// Trigger refetch
			refetch();
		}
	}, [isShown, startDate, endDate, selectedId, refetch]);

 	useEffect(() => {
		if (data) {
			setEvent(data); 
			setIsShown(false);  
			setIsLoading(false);  
		}
	}, [data]);

	return (
		<div>
			 <Helmet>
        <title>Notifications</title>
        <meta name="description" content="Notifications" />
      </Helmet>
			<SecondHeader title="Notifications" />
			<div className="report-sheets">
				<EventsHead
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					selectedUser={selectedUser}
					setSelectedUser={setSelectedUser}
					selectedId={selectedId}
					setSelectedId={setSelectedId}
					isShown={isShown}
					setIsShown={setIsShown}
				/>
				<Table
					HeadField={HeadField}
					BodyData={event}
					Loading={isLoading}
					type="events"
				/>
			</div>
		</div>
	);
}
