/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from "react";
import {
	DatePickerInput,
	Input,
	InputDevicesGroup,
	InputParentGroup,
} from "../../Helpers/Input/Input";
import Button from "../../Helpers/Button/Button";
import { useLocation, useParams } from "react-router-dom";
import { useLazyGetHistoryPositionQuery } from "../../../Redux/service/Positions";
import { useDispatch, useSelector } from "react-redux";
import { setHistoryPositions } from "../../../Redux/service/Websocket";
import { useMap } from "react-leaflet";
import PlayBackControls from "../HistoryPlay/PlayBackControls";
import { toast } from "react-toastify";
import { popupcontext } from "../../../context/Popupscontext";

export default function PlayBackDate() {
	const inf = useSelector((state) => state.users.user);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [minDate, setMinDate] = useState(null);
	const [maxDate, setMaxDate] = useState(null);
	const [media, setMedia] = useState(false);
	const [vid, setVId] = useState();
	const [uid, setUId] = useState(inf?.id);
	const [isLoading, setIsLoading] = useState(false);
	const { pathname } = useLocation();
	const getShortPath = () => pathname.split("/").pop();

	const { setDisplay, handleClose, setPosIndex, setHistoryPlayback } =
		useContext(popupcontext);
	let { id } = useParams();
	let [getHistPos, { data: histData, isSuccess, isError }] =
		useLazyGetHistoryPositionQuery();
	const map = useMap();
	const dispatch = useDispatch();
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toISOString().replace(/:/g, "%3A");
	};

	const handleDateRangeChange = ({ startDate, endDate }) => {
		setStartDate(startDate ? startDate.toISOString() : null);
		setEndDate(endDate ? endDate.toISOString() : null);
	};

	const handleVehicleIdChange = (e) => {
		setVId(e.target.value);
	};

	const handleUserIdChange = (e) => {
		setUId(e.target.value);
	};
	/**const handleFetchData = () => {
		getHistPos({ deviceId: id || vid, from: startDate, to: endDate });
		handleShow("historyPlayBack");
	};**/

	const handleResetData = () => {
		setMedia(false);
		setStartDate("");
		setEndDate("");
		setMinDate(null);
		setMaxDate(null);
		setPosIndex(0);
		handleClose("historyPlayBack");
		dispatch(setHistoryPositions([]));
		setHistoryPlayback({ state: false, Id: 0 });
	};
	const handleFetchData = async () => {
		if ((id || vid) && startDate && endDate) {
			setIsLoading(true); 

			const response = await getHistPos({
				deviceId: id || vid,
				from: startDate,
				to: endDate,
			});

			if (response?.data.length > 0) {
				setDisplay(true);
				dispatch(setHistoryPositions(response.data));
				setMedia(true);
				map.setView(
					[response.data[0]?.latitude, response.data[0]?.longitude],
					22
				);
			} else if (response?.data.length === 0) {
				setIsLoading(false);
				toast.error("No data found"); 
			} else {
				setIsLoading(false);
				toast.error("Error fetching data");
			}
		} else {
			toast.error("Please Fill Inputs First");
		}
	};
	/**	useEffect(() => {
		if (isSuccess) {
			if (histData?.length > 0) {
				dispatch(setHistoryPositions(histData));
				setMedia(true);
				map.setView([histData[0]?.latitude, histData[0]?.longitude], 22);
			} else {
				toast.error("No data found");
			}
		} else if (isError) {
			toast.error("Error fetching data");
		}
	}, [isSuccess, isError, histData, dispatch, map]);*/

	useEffect(() => {
		if (isLoading) {
			const loadingToastId = toast.info("Data Is Loading...");
			return () => toast.dismiss(loadingToastId); // Clear the loading toast on unmount
		}
	}, [isLoading]);
	return (
		<div className="history-playback-dateinput">
			{!media && (
				<>
					{getShortPath(pathname) === "historical" && (
						<>
							<InputParentGroup
								id="parent"
								name="parent"
								title="User"
								formstyle="w-100 mb-2"
								value={uid || ""}
								onChange={handleUserIdChange}
							/>
							<InputDevicesGroup
								title="Vehicle"
								formstyle="w-100 mb-2"
								placeholder="Select Vehicle"
								value={vid || ""}
								userID={uid}
								onChange={handleVehicleIdChange}
							/>
						</>
					)}
					<DatePickerInput
						id="dateRange"
						name="dateRange"
						title="Date Range"
						formstyle="user-form w-100"
						onChange={handleDateRangeChange}
					/>
				</>
			)}
			<div className="text-center">
				{media && histData?.length > 0 && (
					<>
						<PlayBackControls maxValue={histData?.length} />
					</>
				)}
			</div>
			<div className="history-playback-footer">
				<Button
					style={
						media
							? " button fs-14 p-6-12 btn-default"
							: "button fs-14 p-6-12 btn-success ms-3"
					}
					text={media ? "Reset" : "Show"}
					type="button"
					onClick={media ? handleResetData : handleFetchData}
				/>
				{/**<Button
          style="button fs-14 p-6-12 btn-default"
          type="button"
          text="Reset"
          onClick={handleResetData}
        />**/}
			</div>
		</div>
	);
}
