import React, { useContext, useState } from "react";
import TableHeadItem from "../../Helpers/Table/TableHeadItem";
import { filtrationcontext } from "../../../context/Filtercontext";

export default function DriversTableHead() {
	const { Sort, setSort } = useContext(filtrationcontext);
	const [hoveredColumn, setHoveredColumn] = useState(null);
	const Sorting = (type) => {
		setSort((prevSort) => ({
			type: type,
			value: !prevSort.value,
		}));
	};

	return (
		<>
			<div className="table-head-container">
				<div className={`table-head d-flex p-16`}>
					<TableHeadItem
						title="name"
						Sort={() => Sorting("name")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "name"}
						onMouseEnter={() => setHoveredColumn("name")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>
					<TableHeadItem
						title="driver code"
						Sort={() => Sorting("code")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "code"}
						onMouseEnter={() => setHoveredColumn("code")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>
					<TableHeadItem
						title="ID number"
						Sort={() => Sorting("idNo")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "idNo"}
						onMouseEnter={() => setHoveredColumn("idNo")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>

					<TableHeadItem
						title="phone number"
						Sort={() => Sorting("phoneNumber")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "phoneNumber"}
						onMouseEnter={() => setHoveredColumn("phoneNumber")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>

					<TableHeadItem
						title="address"
						Sort={() => Sorting("address")}
						SortType={Sort.value}
						isHovered={hoveredColumn === "address"}
						onMouseEnter={() => setHoveredColumn("address")}
						onMouseLeave={() => setHoveredColumn(null)}
					/>

					<div className="fs-14 fw-400 text-capitalize table-element">
						actions
					</div>
				</div>
			</div>
		</>
	);
}
