import React from "react";
import { useLocation } from "react-router-dom";
import ReportTrackingSpeedTotal from "./ReportTrackingSpeedTotal";
import ReportStopDistanceTotal from "./ReportStopDistanceTotal";
import ReportFuelOdoTotal from "./ReportFuelOdoTotal";
import ReportDistanceTotal from "./ReportDistanceTotal";

export default function ReportSheetsTotal({ data }) {
	let { pathname } = useLocation();
	const getShortPath = () => pathname.split("/").pop();
	return (
		<div className="row">
			{(getShortPath(pathname) === "vehiclestrips" ||
				getShortPath(pathname) === "speed") && (
				<ReportTrackingSpeedTotal data={data?.summary} />
			)}
			{getShortPath(pathname) === "stop" && (
				<ReportStopDistanceTotal data={data?.summary} />
			)}
			{(getShortPath(pathname) === "fuel" ||
				getShortPath(pathname) === "odometer") && (
				<ReportFuelOdoTotal data={data?.summary} />
			)}
			{getShortPath(pathname) === "distance" && (
				<ReportDistanceTotal data={data?.summary} />
			)}
		</div>
	);
}
