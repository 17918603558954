import React, { useContext } from "react";
import { filtrationcontext } from "../../context/Filtercontext";
import TableRow from "../Helpers/Table/TableRow";
import { formatDate } from "../../JsHelpers/DateFormat";

export default function EventsTableBody({ data }) {
	let { EventsSearchQuery } = useContext(filtrationcontext);
	let SearchFilteredData = data;
	if (EventsSearchQuery) {
		SearchFilteredData = data?.filter((i) =>
			i.type.toLowerCase().includes(EventsSearchQuery?.toLowerCase())
		);
	}
	function knotsToKmPerHour(knots) {
		return knots * 1.852;
	}
	return (
		<>
			{SearchFilteredData?.map((i) => {
				let { formattedDate, formattedTime } = formatDate(i.eventTime);
				return (
					<div key={i.id} className="table-row">
						<TableRow
							value={i.type}
							// eslint-disable-next-line react/style-prop-object
							style="table-element-name ps-0 text-capitalize"
						/>

						<TableRow value={formattedDate + " " + formattedTime} />
						<TableRow
							value={i.attributes.speed ? Math.round(knotsToKmPerHour(i.attributes.speed)) + "km/h" : ""}
						/>
					</div>
				);
			})}
		</>
	);
}
