import React, { useContext, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Form from "../Helpers/Form/Form";
import { popupcontext } from "../../context/Popupscontext";
import FormHeader from "../Helpers/Form/FormParts/FormHeader";
import { useSelector } from "react-redux";
import NotificationPopup from "./NotificationPopup";
export default function SideEvents() {
	const { displayNotification, setDisplayAlert, displayAlert } =
		useContext(popupcontext);
	let getAlertType = (type) => {
		if (type === "deviceOverspeed") {
			return "Speed Alert.";
		} else if (type === "geofenceExit" || type === "geofenceEnter") {
			return "Geofence Alert.";
		}
	};
	const getTimeDifference = (eventTime) => {
		const eventDate = new Date(eventTime);
		const now = new Date();
		const diffMs = Math.abs(now - eventDate); // Difference in milliseconds
		const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
		const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

		return `${diffHours}h${diffMinutes}m ago`;
	};
	let [pid, setPId] = useState();
	const events = useSelector((state) => state.webSocket.events);
	return (
		<div className="side-events">
			<Form show={displayNotification} other={true}>
				<Offcanvas.Header>
					<FormHeader title="Notifications" other={true} />
				</Offcanvas.Header>
				<div className="H-line"></div>
				<Offcanvas.Body>
					{events
						.slice()
						.reverse()
						.map((i, indx) => (
							<div
								className="side-notification"
								onClick={() => {
									setDisplayAlert(true);
									setPId(i?.positionId)
								}}
								key={indx}
							>
								<div  className="d-flex">
									<div className="me-2">
										<img
											src={`${process.env.PUBLIC_URL}/assets/SideNotification.svg`}
											alt="Notify"
										/>
									</div>
									<div className="side-notification-content">
										<div className="side-notification-content-title">
											<div>{getAlertType("deviceOverspeed")}</div>
											<div style={{ color: "#7A869A" }}>
												{getTimeDifference(i?.eventTime)}
											</div>
										</div>
										<div>
											{i?.type === "deviceOverspeed"
												? `${i.attributes?.message} with speed limit ${(
														i.attributes.speedLimit * 1.852
												  ).toFixed(1)} km/h`
												: i?.attributes.message}
										</div>
									</div>
								</div>
							</div>
						))}
				</Offcanvas.Body>
			</Form>
			<NotificationPopup positionId={pid}/>
		</div>
	);
}
