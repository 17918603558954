import React from "react";
import SetGeofence from "./SetGeofence/SetGeofence";
import SetSpeedLimit from "./SetSpeedLimit/SetSpeedLimit";
import StartEngine from "./startEngine/StartEngine";
import StopEngine from "./stopEngine/StopEngine";
import SetNotification from "./SetNotification/SetNotification";
import FuelDropThreshold from "./FuelDropThreshold/FuelDropThreshold";
import FuelIncreaseThreshold from "./FuelIncreaseThreshold/FuelIncreaseThreshold";

const PopupBodyContent = ({
  title,
  device,
  setControlsPopup,
  setIsControlsPopup,
}) => {
  switch (title) {
    case "set geofence":
      return <SetGeofence title={title} device={device} />;

    case "set speed limit":
      return (
        <SetSpeedLimit
          title={title}
          device={device}
          setControlsPopup={setControlsPopup}
          setIsControlsPopup={setIsControlsPopup}
        />
      );

    case "start engine":
      return (
        <StartEngine
          title={title}
          device={device}
          setControlsPopup={setControlsPopup}
          setIsControlsPopup={setIsControlsPopup}
        />
      );
    case "fuel drop threshold":
      return (
        <FuelDropThreshold
          title={title}
          device={device}
          setControlsPopup={setControlsPopup}
          setIsControlsPopup={setIsControlsPopup}
        />
      );
    case "fuel increase threshold":
      return (
        <FuelIncreaseThreshold
          title={title}
          device={device}
          setControlsPopup={setControlsPopup}
          setIsControlsPopup={setIsControlsPopup}
        />
      );
    case "stop engine":
      return (
        <StopEngine
          title={title}
          device={device}
          setControlsPopup={setControlsPopup}
          setIsControlsPopup={setIsControlsPopup}
        />
      );
    case "set notification":
      return <SetNotification title={title} device={device} />;

    default:
      return <></>;
  }
};

export default PopupBodyContent;
