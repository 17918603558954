/* eslint-disable react/style-prop-object */
import React, { useContext, useState } from "react";
import Searchbar from "../../Helpers/Searchbar/Searchbar";
import { filtrationcontext } from "../../../context/Filtercontext";
import Button from "../../Helpers/Button/Button";
import { popupcontext } from "../../../context/Popupscontext";
import EventsFilter from "../EventsFilter/EventsFilter";

export default function EventsHead({
	setStartDate,
	startDate,
	setEndDate,
	endDate,
	selectedUser,
	selectedId,
	setSelectedUser,
	setSelectedId,
	setIsShown,
	isShown,
}) {
	const { setEventSearchQuery } = useContext(filtrationcontext);
	const { handleShow } = useContext(popupcontext);
	const [query, setQuery] = useState("");
	const handleSearch = (e) => {
		setQuery(e.target.value);
		setEventSearchQuery(e.target.value);
	};

	return (
		<>
			<div className="flex-between events-head mb-3">
				<Searchbar
					style="search search-dark input-group"
					path="/assets/search.svg"
					placeholder="Search"
					value={query}
					onChange={handleSearch}
				/>
				<Button
					style="button fw-400 fs-16 me-2 btn-default"
					text="Filter"
					img="Filter.svg"
					onClick={() => {
						handleShow("addForm");
						setIsShown(false);
					}}
				/>
				<EventsFilter
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
					selectedUser={selectedUser}
					selectedId={selectedId}
					setSelectedUser={setSelectedUser}
					setSelectedId={setSelectedId}
					isShown={isShown}
					setIsShown={setIsShown}
				/>
			</div>
		</>
	);
}
