import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

export default function Form({ show, children , other }) {
  let width = !other ? "form-width" : "other-width" 
	return (
		<>
			<Offcanvas
				show={show}
				backdrop="static"
				placement="end"
				className={`${width} overflow-y-scroll`}
			>
				{children}
			</Offcanvas>
		</>
	);
}
