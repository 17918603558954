import React from "react";
import AreaCnart from "../../../Charts/AreaChart";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import the skeleton styles

export default function TotalComponent(props) {
  return (
    <>
      {props.data && props.labels ? (
        <div className={`dashboard-total  ${props.style}`}>
          <div className="flex-between">
            <div>
              <p className="fs-16 fw-600 neutral-500">{props.title}</p>
              <div className="mb-3">
                <img
                  className="me-3"
                  src={
                    props.percent > 0
                      ? `${process.env.PUBLIC_URL}/assets/Increase.svg`
                      : `${process.env.PUBLIC_URL}/assets/Decrease.svg`
                  }
                  alt=""
                />
                <span
                  className={`fs-14 fw-700 ${
                    props.percent > 0 ? "brand-500" : "danger"
                  }`}
                >
                  {props.percent > 0 ? `+${props.percent}` : props.percent}
                </span>{" "}
                in this month
              </div>
              <div className="flex-center">
                <span className="fs-24 fw-600">{props.number}</span>
                <div className="dashboard-total-chart">
                  <AreaCnart
                    data={props.data}
                    color={props.color}
                    labels={props.labels}
                    chartName={props.title}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={`dashboard-total skeleton ${props.style}`}>
          <div className="flex-between">
            <div>
              <p className="fs-16 fw-600">
                <Skeleton width={150} height={20} />
              </p>
              <div className="mb-3 flex-center">
                <Skeleton circle width={30} height={30} className="me-3" />
                <Skeleton width={100} height={20} />
              </div>
              <div className="flex-center">
                <Skeleton width={50} height={30} />
                <div className="dashboard-total-chart">
                  <Skeleton width={150} height={60} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
