import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { webSocketManager } from "../../Sockets/WebSocketManager";
import { setEvents } from "../../Redux/service/Websocket";
import { toast } from "react-toastify";

export default function LiveEvents() {
	let [length, setLength] = useState(0);
	const events = useSelector((state) => state.webSocket.events);
	const webSocketData = useSelector((state) => state.webSocket.data);
	const dispatch = useDispatch();

	useEffect(() => {
		if (document.cookie.includes("JSESSIONID")) {
			webSocketManager.connect();
		}
	}, []);

	useEffect(() => {
		if (!webSocketData?.events) return;

		const updatedEvents = [...events];
		const existingIds = new Set(events.map((event) => event.id));

		const newEvents = webSocketData.events.filter(
			(event) => !existingIds.has(event.id)
		);

		if (newEvents.length > 0) {
			//console.log("New WebSocket Events", webSocketData.events);
			updatedEvents.push(...newEvents);
			dispatch(setEvents(updatedEvents));
		}
	}, [webSocketData?.events, events, dispatch]);

	useEffect(() => {
		if (events.length > length) {
			setLength(events.length);
			const latestEvent = events[events.length - 1];
			if (latestEvent) {
				let imageSrc = "";
				let bgColor = "";
				let message = "";
				switch (latestEvent.type) {
					case "deviceOverspeed":
						imageSrc = "/assets/SpeedAlert.svg";
						bgColor = "red";
						message = `${latestEvent.attributes?.message} with speed limit ${(
							latestEvent.attributes.speedLimit * 1.852
						).toFixed(1)} km/h`;
						break;
					case "geofenceExit":
						imageSrc = "/assets/GeofenceAlert.svg";
						bgColor = "#3b82f6";
						message = `${latestEvent.attributes?.message}`;
						break;
					case "geofenceEnter":
						imageSrc = "/assets/GeofenceAlert.svg";
						bgColor = "#3b82f6";
						message = `${latestEvent.attributes?.message}`;
						break;
					default:
						imageSrc = "/assets/OtherAlert.svg";
						bgColor = "#9e959f";
				}
				toast.info(
					<div className="alertnotification">
						<img src={imageSrc} alt="Alert" style={{ marginRight: 10 }} />
						<span>{message}</span>
					</div>,
					{
						className: "toast-event",
						icon: false,
						style: { backgroundColor: bgColor, color: "white" },
					}
				);
			}
		}
	}, [events, length]);
	return <div></div>;
}
