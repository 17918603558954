/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from "react";
import TotalComponent from "./DashboardTotal/TotalComponent";
import {
  useDashboardChartsQuery,
  useDashboardCountDifferenceQuery,
  useGetAllVehiclesQuery,
} from "../../../Redux/service/Vehicles/Vehicles";
import { filtrationcontext } from "../../../context/Filtercontext";
import { useGetAllDriversQuery } from "../../../Redux/service/Drivers/Drivers";
import { useDispatch } from "react-redux";
import { setTotalCount } from "../../../Redux/service/Vehicles/VehiclesSlice";
import { useGetAllUsersQuery } from "../../../Redux/service/Users/Users";

const percent = {
  maintenanceDifference: "0",
};

export default function DashboardTotal() {
  const { limit } = useContext(filtrationcontext);
  const [currentPage] = useState(Number(localStorage.getItem("page")) || 1);
  const dispatch = useDispatch();
  const { data: countDiff } = useDashboardCountDifferenceQuery();
  const { data: chartsData } = useDashboardChartsQuery();
  const { data: vehiclesData, isLoading } = useGetAllVehiclesQuery(
    { page: currentPage, limit: limit },
    {
      skip: !currentPage || !limit,
    }
  );
  let { data: DriverData, isLoading: DriverLoading } = useGetAllDriversQuery(
    { page: currentPage, limit: limit },
    {
      skip: !currentPage || !limit,
    }
  );
  let { data: UserData } = useGetAllUsersQuery();
  useEffect(() => {
    dispatch(setTotalCount(vehiclesData?.totalCount));
  }, [dispatch, vehiclesData?.totalCount]);
  return (
    <>
      <div className="bg-white-rounded">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <TotalComponent
            title="Total Vehicles"
            percent={countDiff?.vehicles?.vehiclesAddedThisMonth}
            number={isLoading ? "Loading..." : `${vehiclesData?.totalCount}`}
            chart="Purplechart"
            color="#A855F7"
            labels={chartsData?.months}
            data={chartsData?.vehicles}
          />
          <div className="vertical-line vertical-line-1"></div>
          <TotalComponent
            title="Total Drivers"
            percent={countDiff?.drivers?.driversAddedThisMonth}
            number={DriverLoading ? "Loading..." : `${DriverData?.totalCount}`}
            chart="Bluechart"
            color="#3B82F6"
            labels={chartsData?.months}
            data={chartsData?.drivers}
          />
          <div className="vertical-line vertical-line-2"></div>
          <TotalComponent
            title="Total Users"
            percent={countDiff?.users?.usersAddedThisMonth}
            number={!UserData ? "Loading..." : `${UserData?.length}`}
            chart="Redchart"
            color="#EF4444"
            labels={chartsData?.months}
            data={chartsData?.users}
          />
          <div className="vertical-line vertical-line-3"></div>
          <TotalComponent
            title="Maintenance Requests"
            percent={percent.maintenanceDifference}
            number="500"
            chart="Greenchart"
            color="#2E9245"
            labels={chartsData?.months}
            data={chartsData?.vehicles}
          />
        </div>
      </div>
    </>
  );
}
