import React from "react";

export default function TableHeadItem({ title, Sort, SortType , isHovered , onMouseEnter , onMouseLeave }) {
	return (
		<div
			className="fs-14 fw-400 text-capitalize table-element cursor"
			onClick={Sort}
            onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="d-flex bg-transparent">
				<div>{title}</div>
				{isHovered && (
					<img
						className="sortimg"
						src={
							SortType
								? `${process.env.PUBLIC_URL}/assets/DescendingSort.svg`
								: `${process.env.PUBLIC_URL}/assets/AscendingSort.svg`
						}
						alt="sort"
					/>
				)}

			</div>
		</div>
	);
}
