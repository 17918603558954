/* eslint-disable react/style-prop-object */
import React, { useContext } from "react";
import TableRow from "../../Helpers/Table/TableRow";
import TableActions from "../../Helpers/Table/TableActions";
import { filtrationcontext } from "../../../context/Filtercontext";

export default function NotificationTableBody({ data }) {
  let { NotifictionSearchQuery } = useContext(filtrationcontext);
  let SearchFilteredData = data;
  if (NotifictionSearchQuery) {
    SearchFilteredData = data?.filter((i) =>
      i.type.toLowerCase().includes(NotifictionSearchQuery?.toLowerCase())
    );
  }
  let getAlertType = (type) => {
    if (type === "deviceOverspeed") {
      return "Speed Alert.";
    } else if (type === "geofenceExit") {
      return "Geofence exited.";
    } else if (type === "geofenceEnter") {
      return "Geofence entered.";
    } else if (type === "deviceFuelDrop") {
      return "Fuel Drop";
    } else if (type === "deviceFuelIncrease") {
      return "Fuel Increase";
    }
  };
  return (
    <>
      {SearchFilteredData?.map((i) => (
        <div key={i.id} className="table-row">
          <TableRow
            value={i.type}
            style="table-element-name ps-0 text-capitalize"
          />
          <TableRow value={i.notificators} />
          <TableActions notifiyid={i.id} type="notifications" />
        </div>
      ))}
    </>
  );
}
