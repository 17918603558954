import React, { useContext, useEffect } from "react";
import Sidebar from "../SideBar/Sidebar";
import { sidebarcontext } from "../../context/Sidebarcontext";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import { webSocketManager } from "../../Sockets/WebSocketManager";
import LiveEvents from "../LiveEvents/LiveEvents";
import SideEvents from "../LiveEvents/SideEvents";
import axios from "axios";

export default function Mainlayout() {
	let { isSideOpen } = useContext(sidebarcontext);
	const location = useLocation();
	let path = location.pathname;
	const ScreenClass = isSideOpen
		? path === "/" || path === "/dashboard"
			? "outlet-content-max p-16"
			: "outlet-content-max"
		: path !== "/" && path !== "/dashboard"
		? "outlet-content-min"
		: "outlet-content-min p-16";
	useEffect(() => {
		if (!document.cookie && localStorage.getItem("token")) {
			axios.get(
				`https://test.passenger-mea.com/api/session?token=${localStorage.getItem(
					"token"
				)}`,
				{
					withCredentials: true,
				}
			);
			webSocketManager.connect();
		}
	}, []);
	return (
		<>
			<Sidebar />
			<div>
				<Header />
				<div className={ScreenClass}>
					<LiveEvents />
					<SideEvents />
					<Outlet />
				</div>
			</div>
		</>
	);
}
